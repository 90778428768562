import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import {
    faFacebook as fabFacebook,
    faInstagram as fabInstagram,
    faLinkedin as fabLinkedin,
    faStripe as fabStripe,
    faTwitter as fabTwitter,
    faYoutube as fabYoutube
} from '@fortawesome/free-brands-svg-icons';

import {
    faCircle as fadCircle,
    faCogs as fadCogs,
    faCommentAltLines as fadCommentAltLines,
    faSortDown as fadSortDown,
    faSortUp as fadSortUp,
    faUser as fadUser
} from '@fortawesome/pro-duotone-svg-icons';

import {
    faAnalytics as fasAnalytics,
    faFilter as fasFilter,
    faFileUser as fasFileUser,
    faArrowRight as fasArrowRight,
    faArrowToBottom as fasArrowToBottom,
    faBars as fasBars,
    faInventory as fasInventory,
    faCheckDouble as fasCheckDouble,
    faBan as fasBan,
    faSignalStream as fasSignalStream,
    faBell as fasBell,
    faQrcode as fasQrcode,
    faEdit as fasEdit,
    faBinoculars as fasBinoculars,
    faBookmark as fasBookmark,
    faBoxCheck as fasBoxCheck,
    faBoxes as fasBoxes,
    faBoxesAlt as fasBoxesAlt,
    faBuilding as fasBuilding,
    faCalendar as fasCalendar,
    faCalendarAlt as fasCalendarAlt,
    faCalendarDay as fasCalendarDay,
    faCalendarWeek as fasCalendarWeek,
    faCheck as fasCheck,
    faCheckCircle as fasCheckCircle,
    faCircle as fasCircle, faCirclePause as fasCirclePause, faCirclePhone as fasCirclePhone, faCirclePhoneHangup as fasCirclePhoneHangup, faClock as fasClock,
    faCog as fasCog,
    faCoins as fasCoins,
    faCommentAltLines as fasCommentAltLines,
    faComments as fasComments,
    faEnvelope as fasEnvelope,
    faExclamationCircle as fasExclamationCircle,
    faExclamationTriangle as fasExclamationTriangle,
    faExternalLink as fasExternalLink,
    faEye as fasEye,
    faEyeSlash as fasEyeSlash,
    faFile as fasFile,
    faFilePdf as fasFilePdf,
    faGift as fasGift,
    faGlobe as fasGlobe,
    faHashtag as fasHashtag,
    faHome as fasHome,
    faInfoCircle as fasInfoCircle,
    faLayerGroup as fasLayerGroup,
    faLayerPlus as fasLayerPlus,
    faLocation as fasLocation,
    faLocationArrow as fasLocationArrow,
    faLock as fasLock,
    faLockOpen as fasLockOpen,
    faMapMarker as fasMapMarker,
    faMoneyBillsSimple as fasMoneyBillsSimple,
    faPeopleCarry as fasPeopleCarry,
    faPercent as fasPercent,
    faPercentage as fasPercentage, faPhone as fasPhone, faPhoneArrowDownLeft as fasPhoneArrowDownLeft, faPhoneArrowUpRight as fasPhoneArrowUpRight, faPhoneSlash as fasPhoneSlash, faPhoneVolume as fasPhoneVolume, faPhoneXmark as fasPhoneXmark, faPoundSign as fasPoundSign,
    faQuestion as fasQuestion,
    faRedo as fasRedo,
    faSignIn as fasSignIn,
    faSignOutAlt as fasSignOutAlt,
    faSlash as fasSlash,
    faSplit as fasSplit,
    faStar as fasStar,
    faTags as fasTags,
    faTimes as fasTimes,
    faTimesCircle as fasTimesCircle,
    faTruck as fasTruck,
    faCirclePlus as fasCirclePlusSolid,
    faTruckLoading as fasTruckLoading,
    faUser as fasUser, faUserCheck as fasUserCheck,
    faUserCog as fasUserCog,
    faUserGroup as fasUserGroup,
    faUserCrown as fasUserCrown, 
    faUsers as fasUsers, faUsersClass as fasUsersClass
} from '@fortawesome/pro-solid-svg-icons';

import { fal } from '@fortawesome/pro-light-svg-icons';

import { fat } from '@fortawesome/pro-thin-svg-icons';

import FACustomIcon from 'Components/Common/Icons/FontAwesome/FACustomIcon';

library.add(
    fasCirclePlusSolid,
    fasFileUser,
    fasFilter,
    fabFacebook,
    fabInstagram,
    fabLinkedin,
    fabStripe,
    fabTwitter,
    fabYoutube,
    fadCircle,
    fadCogs,
    fadCommentAltLines,
    fadSortDown,
    fadSortUp,
    fadUser,
    fal,
    fasAnalytics,
    fasArrowRight,
    fasArrowToBottom,
    fasBars,
    fasInventory,
    fasCheckDouble,
    fasBan,
    fasBell,
    fasQrcode,
    fasEdit,
    fasBinoculars,
    fasBookmark,
    fasBoxCheck,
    fasBoxes,
    fasBoxesAlt,
    fasBuilding,
    fasCalendar,
    fasCalendarAlt,
    fasCalendarDay,
    fasCalendarWeek,
    fasCheck,
    fasCheckCircle,
    fasCircle,
    fasClock,
    fasCog,
    fasCoins,
    fasCommentAltLines,
    fasSignalStream,
    fasComments,
    fasEnvelope,
    fasGlobe,
    fasExclamationCircle,
    fasExclamationTriangle,
    fasExternalLink,
    fasEye,
    fasEyeSlash,
    fasFile,
    fasFilePdf,
    fasGift,
    fasHashtag,
    fasHashtag,
    fasHome,
    fasInfoCircle,
    fasLayerGroup,
    fasLayerPlus,
    fasLocation,
    fasLocationArrow,
    fasLock,
    fasLockOpen,
    fasMapMarker,
    fasMoneyBillsSimple,
    fasPeopleCarry,
    fasPercent,
    fasPercentage,
    fasPhone,
    fasPhoneSlash,
    fasCirclePause,
    fasPhoneXmark,
    fasCirclePhone,
    fasCirclePhoneHangup,
    fasUserGroup,
    fasPhoneVolume,
    fasPhoneArrowUpRight,
    fasPhoneArrowDownLeft,
    fasPoundSign,
    fasQuestion,
    fasRedo,
    fasSignIn,
    fasSignOutAlt,
    fasSlash,
    fasSplit,
    fasStar,
    fasTags,
    fasTimes,
    fasTimesCircle,
    fasTruck,
    fasTruckLoading,
    fasUser,
    fasUsers,
    fasUserCheck,
    fasUserCog,
    fasUserCrown,
    fasUsersClass,
    fat
);

const iconType = (type) => {
    return  (type === 'solid' && 'fas') ||
            (type === 'brand' && 'fab') ||
            (type === 'duo' && 'fad') ||
            (type === 'thin' && 'fat') ||
            'fal'
}

const customIcons = [
    "account-review",
    "address-card-primary",
    "invoice",
    "location-circle-primary",
    "purchase",
    "purchase-slash",
    "quote",
    "quote-slash",
    "order",
    "order-slash",
    "pound-sign-slash",
    "sack-pound",
    "search-pound",
    "vat"
];

const FAIcon = ({
        button, 
        buttonPrimary, 
        color, 
        className, 
        disabled, 
        error, 
        fixedWidth,
        fixedHeight,
        icon,
        light,
        noMargin,
        rotation, 
        size, 
        spin, 
        style, 
        transform,
        type
    }) => {
        
    const iconSize = (!isNaN(size) && size)           ||
                        (size === 'avatar' && 32)     || 
                        (size === 'xxlarge' && 75)    || 
                        (size === 'xlarge' && 50)     || 
                        (size === 'large' && 35)      || 
                        (size === 'small' && 15)      || 
                        20;

    const width = (fixedHeight && 'auto') || iconSize,
          height = (fixedWidth && 'auto') || iconSize;

    return (
        (customIcons.includes(icon) && (
            <FACustomIcon 
                className={className} 
                icon={icon} 
                size={iconSize} 
                noMargin={noMargin && true}
                style={style}
                type={type}
            />
        )) || (
            <FontAwesomeIcon    
                icon={[
                    iconType(type), 
                    icon
                ]}
                rotation={
                    rotation && rotation
                } 
                spin={
                    spin && spin
                }
                transform={
                    transform && transform
                }
                className={
                    (className && className) ||
                    (disabled && 'iconStyleDisabled') ||
                    (error && 'iconStyleError') ||
                    (light && 'iconStyleLight') || 
                    'iconStyle'
                }
                style={{
                    width: width,
                    height: height,
                    margin:
                        (noMargin) && 0,
                    cursor: 
                        (button || buttonPrimary) && 'pointer',
                    color: 
                        (disabled && (
                            undefined      
                        )) || (
                            (buttonPrimary && !disabled && '#fff')  || 
                            (color === 'primary' && '#ef3340')      || 
                            (color === 'info' && '#0288D1')         || 
                            (color === 'error' && '#d32f2f')        ||  
                            (color === 'success' && '#388E3C')      ||  
                            (color === 'white' && '#fff')           ||
                            color
                        ),
                    ...style
                }}
            />
        )
    )
}

export default FAIcon;