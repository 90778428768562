import React from "react";
import moment from "moment";
import _ from "lodash";

import { 
    useDispatch, 
    useSelector 
} from "react-redux";

import Box from "@material-ui/core/Box";
import FAIcon from "Components/Common/Icons/FontAwesome/FAIcon";

import API from "API";
import BuildOrderDelivery from "Components/Technical/Misc/BuildOrderDelivery";
import DataTable from "Components/Common/DataTables/DataTable";
import DeliverySummary from "Components/Sales/Misc/DeliverySummary";
import InsightChip from "Components/Common/Chips/InsightChip";
import InvoiceDelivery from "Components/Sales/Misc/InvoiceDelivery";
import OrderSummary from "Components/Sales/Misc/OrderSummary";
import StaffChip from "Components/Common/Chips/StaffChip";
import StatusChip from "Components/Common/Chips/StatusChip";

import { deployConfirmation } from "Redux/Actions/Confirmation/Confirmation";
import { deployDialog } from "Redux/Actions/Dialog/Dialog";
import { deploySnackBar } from "Redux/Actions/SnackBar/SnackBar";
import { getPresignedUrl } from "Functions/S3Functions";
import { pdfFromBase64 } from "Helpers/PDFHelper";
import { Typography } from "@material-ui/core";

const getLegacySalesOrderPDF = (id) => {
    if (id) {
        API.get("/sales/" + id + "/legacy/pdf").then((result) => {
            if (result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        });
    }
};

const getLegacyOrderDeliveryInvoicePDF = (deliveryId) => {
    API.get("/sales/delivery/" + deliveryId + "/legacy/pdf", { params: { invoice: true } }).then((result) => {
        if (result.data) {
            pdfFromBase64(result.data.pdf, result.data.pdfName);
        }
    });
};

const handleInProgressUser = (callback, dispatch, orderId, reviewId) => {
    dispatch(
        deployConfirmation(`Are you sure you want to ${reviewId > 0 ? `remove the review flag from this sales order?` : `mark this sales order as under review?`}`, () => {
            let route = `/sales/${orderId}/processing/user/${reviewId > 0 ? `clear` : `set`}`;

            API.put(route).then((res) => {
                if (res?.data?.success) {
                    callback?.();
                    dispatch(deploySnackBar(`success`, `You have successfully ${reviewId > 0 ? `cleared the review flag for this sales order` : `marked this sales order as under review`}`));
                }
            });
        })
    );
};

const deployPreview = (dispatch, type, orderId, odId = null) => {
    switch(type) {
        case "order":
            API.get(`/sales/${orderId}`)
            .then(res => {
                if (res?.data) {
                    const order = res.data;
                    dispatch(
                        deployDialog(
                            <OrderSummary 
                                inDialog
                                order={order} 
                            />
                        , 
                        true, 
                        ``, 
                        "standard", 
                        "xl",
                        )
                    );
                }
            });
        break;
        case "od": 
            API.get(`/sales/${orderId}`)
            .then(res => {
                if (res?.data) {
                    const   order       = res.data,
                            od          = _.find(order.deliveries, { od_id: odId}),
                            idx         = od?.od_idx ?? null,
                            access      = {};

                    if(order && od) {
                        dispatch(     
                            deployDialog(
                                <Box p={3} style={{background: '#fafafa'}}>
                                    <DeliverySummary 
                                        access={access}
                                        idx={idx}
                                        inDialog
                                        od={od}
                                        order={order} 
                                    />
                                </Box>
                            , 
                            true, 
                            ``, 
                            "standard", 
                            "xl",
                            )
                        );
                    }
                }
            });
        break;
        default: 
            /* No action */
    }
};

const getColumns = (orderStatus, staff, warehouse, access, basic, dispatch, callback, buildOrders) => {
    if (basic) {
        return [
            {
                heading: "SO Number",
                field: (rowData) => rowData.ref,
                dataRef: "ref",
                main: true,
                sizeToContent: true,
            },
            {
                heading: "Order Date",
                field: (rowData) => rowData.created,
                fieldFormat: "date",
                dataRef: "created",
                sizeToContent: true,
            },
            {
                heading: "Customer Reference",
                field: (rowData) => rowData.crf || "-",
                truncate: true,
                dataRef: "crf",
                main: true,
                sizeToContent: true,
            },
            {
                heading: "Net Total",
                field: (rowData) => rowData.net,
                fieldFormat: "currency",
                dataRef: "net",
                alignment: "right",
                sizeToContent: true,
            },
            {
                heading: "Despatched",
                field: (rowData) => (rowData.status === "Despatched" || rowData.status === "Invoiced" ? true : false),
                fieldFormat: "boolean",
                alignment: "center",
                sizeToContent: true,
                dataRef: "des",
            },
            {
                heading: "Invoiced",
                field: (rowData) => (rowData.status === "Invoiced" ? true : false),
                fieldFormat: "boolean",
                alignment: "center",
                sizeToContent: true,
                dataRef: "inv",
            },
            {
                heading: "Ordered By",
                field: (rowData) => (staff && (staff[rowData.staff] || rowData.staff === -1) && <StaffChip staff={staff[rowData.staff]} missingText={rowData.staff === -1 ? 'E-commerce' : null } />) || "-",
                dataRef: "staff",
                sizeToContent: true,
                fieldFormat: "staff",
            },
            {
                heading: "Status",
                field: (rowData) => <StatusChip status={rowData.status} />,
                dataRef: "status",
                sizeToContent: true,
            },
            {
                actions: (rowData) => [
                    { name: "View Sales Order", icon: "binoculars", link: `/sales/${rowData.id}`, doubleClick: true },
                    { name: "Preview Sales Order", icon: "eye", onClick: () => deployPreview(dispatch, 'order', rowData.id) },
                    { name: "Download Sales Order PDF", icon: "file-pdf", color: "error", onClick: () => (rowData.legacy ? getLegacySalesOrderPDF(rowData.id) : getPresignedUrl(rowData.pdf)) },
                ],
            },
        ];
    } else if (buildOrders) {
        return [
            {
                heading: "SO Number",
                field: (rowData) => rowData.ref,
                dataRef: "ref",
                main: true,
                // cellProps: rowData => ({
                //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                // })
            },
            {
                heading: "Picked By",
                field: (rowData) => (staff && staff[rowData.psid] && <StaffChip staff={staff[rowData.psid]} />) || "-",
                dataRef: "staff",
                truncate: true,
                fieldFormat: "staff",
                // cellProps: rowData => ({
                //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                // })
            },
            {
                heading: "Order Date",
                field: (rowData) => rowData.created,
                fieldFormat: "date",
                dataRef: "created",
                // cellProps: rowData => ({
                //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                // })
            },
            {
                heading: "Customer",
                field: (rowData) => rowData.customer,
                dataRef: "customer",
                truncate: true,
                // cellProps: rowData => ({
                //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                // })
            },
            {
                heading: "Account Number",
                field: (rowData) => rowData.acc,
                dataRef: "acc",
                main: true,
                sizeToContent: true,
                // cellProps: rowData => ({
                //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                // })
            },
            {
                heading: "Customer Reference",
                field: (rowData) => rowData.crf || "-",
                truncate: true,
                dataRef: "crf",
                main: true,
                sizeToContent: true,
                // cellProps: rowData => ({
                //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                // })
            },
            {
                heading: "Net Total",
                field: (rowData) => rowData.net,
                fieldFormat: "currency",
                dataRef: "net",
                alignment: "right",
                // cellProps: rowData => ({
                //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                // })
            },
            {
                heading: "Expected Despatch Date",
                field: (rowData) => rowData.despatch,
                fieldFormat: "date",
                dataRef: "despatch",
                // cellProps: rowData => ({
                //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                // })
            },
            {
                actions: (rowData) => [
                    { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}` },
                    { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) },
                    {
                        name: "Process Build",
                        icon: "screwdriver-wrench",
                        onClick: () => {
                            dispatch(deployDialog(<BuildOrderDelivery callback={callback} id={rowData.id} />, false, `Technical Build - Sales Order Delivery #${rowData.ref}`, "standard", "lg", true, false));
                        },
                        doubleClick: true,
                    },
                ],
            },
        ];
    } else {
        switch (orderStatus) {
            case "CompactOnHold":
                return [
                    {
                        heading: "SO #",
                        field: (rowData) => rowData.ref,
                        dataRef: "ref",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Customer",
                        field: (rowData) => rowData.customer,
                        dataRef: "customer",
                        main: true,
                        truncate: true,
                    },
                    {
                        heading: "Hold Reason",
                        field: (rowData) => rowData.hRsn,
                        dataRef: "hRsn",
                    },
                    {
                        heading: "Hold Status",
                        field: (rowData) => <StatusChip status={rowData.hSts} />,
                        dataRef: "hSts",
                    },
                    {
                        actions: (rowData) => [
                            { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}`, doubleClick: true },
                            { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) }
                        ],
                    },
                ];

            case "CompactToChase":
                return [
                    {
                        heading: "SO #",
                        field: (rowData) => rowData.ref,
                        dataRef: "ref",
                        main: true,
                        // cellProps: rowData => ({
                        //     className: rowData.allocated === 0 ? 'textError' : undefined
                        // })
                    },
                    {
                        heading: "Customer",
                        field: (rowData) => rowData.customer,
                        dataRef: "customer",
                        truncate: true,
                        // cellProps: rowData => ({
                        //     className: rowData.allocated === 0 ? 'textError' : undefined
                        // })
                    },
                    {
                        heading: "Supplier",
                        field: (rowData) => rowData.supp ?? "-",
                        dataRef: "supp",
                        main: true,
                        truncate: true,
                        // cellProps: rowData => ({
                        //     className: rowData.allocated === 0 ? 'textError' : undefined
                        // })
                    },
                    {
                        heading: "PO #",
                        field: (rowData) => `${rowData.poRef ?? "-"}`,
                        dataRef: "poRef",
                        main: true,
                        sizeToContent: true,
                        // cellProps: rowData => ({
                        //     className: rowData.allocated === 0 ? 'textError' : undefined
                        // })
                    },
                    {
                        heading: "Check Date",
                        field: (rowData) => rowData.check,
                        fieldFormat: "date",
                        dataRef: "check",
                        cellProps: (rowData) => ({
                            className: moment(moment(rowData.check, "DD/MM/YYYY").format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD")) ? "textError" : undefined,
                        }),
                    },
                    {
                        actions: (rowData) => [
                            { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}`, doubleClick: true },
                            { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id)}
                        ],
                    },
                ];

            case "all":
                return [
                    {
                        heading: "SO Number",
                        field: (rowData) => rowData.ref,
                        dataRef: "ref",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Order Date",
                        field: (rowData) => rowData.created,
                        fieldFormat: "date",
                        dataRef: "created",
                        sizeToContent: true,
                    },
                    {
                        heading: "Customer",
                        field: (rowData) => rowData.customer,
                        dataRef: "customer",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Account Number",
                        field: (rowData) => rowData.acc,
                        dataRef: "acc",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Customer Reference",
                        field: (rowData) => rowData.crf || "-",
                        truncate: true,
                        dataRef: "crf",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Net Total",
                        field: (rowData) => rowData.net,
                        fieldFormat: "currency",
                        dataRef: "net",
                        alignment: "right",
                        sizeToContent: true,
                    },
                    {
                        heading: "Despatched",
                        field: (rowData) => (rowData.status === "Despatched" || rowData.status === "Invoiced" ? true : false),
                        fieldFormat: "boolean",
                        alignment: "center",
                        sizeToContent: true,
                        dataRef: "des",
                    },
                    {
                        heading: "Invoiced",
                        field: (rowData) => (rowData.status === "Invoiced" ? true : false),
                        fieldFormat: "boolean",
                        alignment: "center",
                        sizeToContent: true,
                        dataRef: "inv",
                    },
                    {
                        heading: "Ordered By",
                        field: (rowData) => (staff && (staff[rowData.staff] || rowData.staff === -1) && <StaffChip staff={staff[rowData.staff]} missingText={rowData.staff === -1 ? 'E-commerce' : null } />) || "-",
                        dataRef: "staff",
                        sizeToContent: true,
                        fieldFormat: "staff",
                    },
                    {
                        heading: "Status",
                        field: (rowData) => <StatusChip status={rowData.status} />,
                        dataRef: "status",
                        sizeToContent: true,
                    },
                    {
                        actions: (rowData) => [
                            { name: "View Sales Order", icon: "binoculars", link: `/sales/${rowData.id}`, doubleClick: true },
                            { name: "Preview Sales Order", icon: "eye", onClick: () => deployPreview(dispatch, 'order', rowData.id) },
                            { name: "Download Sales Order PDF", icon: "file-pdf", color: "error", onClick: () => (rowData.legacy ? getLegacySalesOrderPDF(rowData.id) : getPresignedUrl(rowData.pdf)) },
                        ],
                    },
                ];

            case "Cancelled":
                return [
                    {
                        heading: "SO Number",
                        field: (rowData) => rowData.ref,
                        dataRef: "ref",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Order Date",
                        field: (rowData) => rowData.created,
                        fieldFormat: "date",
                        dataRef: "created",
                        sizeToContent: true,
                    },
                    {
                        heading: "Customer",
                        field: (rowData) => rowData.customer,
                        dataRef: "customer",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Account Number",
                        field: (rowData) => rowData.acc,
                        dataRef: "acc",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Customer Reference",
                        field: (rowData) => rowData.crf || "-",
                        dataRef: "crf",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Gross Total",
                        field: (rowData) => rowData.gross,
                        fieldFormat: "currency",
                        dataRef: "gross",
                        alignment: "right",
                        sizeToContent: true,
                    },
                    {
                        heading: "Despatched",
                        field: (rowData) => (rowData.status === "Despatched" || rowData.status === "Invoiced" ? true : false),
                        fieldFormat: "boolean",
                        alignment: "center",
                        sizeToContent: true,
                        dataRef: "des",
                    },
                    {
                        heading: "Invoiced",
                        field: (rowData) => (rowData.status === "Invoiced" ? true : false),
                        fieldFormat: "boolean",
                        alignment: "center",
                        sizeToContent: true,
                        dataRef: "inv",
                    },
                    {
                        heading: "Status",
                        field: (rowData) => <StatusChip status={rowData.status} />,
                        dataRef: "status",
                        sizeToContent: true,
                    },
                    {
                        actions: (rowData) => [
                            { name: "View Sales Order", icon: "binoculars", link: `/sales/${rowData.id}`, doubleClick: true },
                            { name: "Preview Sales Order", icon: "eye", onClick: () => deployPreview(dispatch, 'order', rowData.id) },
                            { name: "Download Sales Order PDF", icon: "file-pdf", color: "error", onClick: () => (rowData.legacy ? getLegacySalesOrderPDF(rowData.id) : getPresignedUrl(rowData.pdf)) },
                        ],
                    },
                ];

            case "Awaiting Approval":
                return [
                    {
                        heading: "SO Number",
                        field: (rowData) => rowData.ref,
                        dataRef: "ref",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Order Date",
                        field: (rowData) => rowData.created,
                        fieldFormat: "date",
                        dataRef: "created",
                        sizeToContent: true,
                    },
                    {
                        heading: "Customer",
                        field: (rowData) => rowData.customer,
                        dataRef: "customer",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Account Number",
                        field: (rowData) => rowData.acc,
                        dataRef: "acc",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Customer Reference",
                        field: (rowData) => rowData.crf || "-",
                        truncate: true,
                        dataRef: "crf",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Net Total",
                        field: (rowData) => rowData.net,
                        fieldFormat: "currency",
                        dataRef: "net",
                        alignment: "right",
                        sizeToContent: true,
                    },
                    {
                        heading: "Gross Total",
                        field: (rowData) => rowData.gross,
                        fieldFormat: "currency",
                        dataRef: "gross",
                        alignment: "right",
                        sizeToContent: true,
                    },
                    {
                        heading: "Approval Type",
                        field: (rowData) => rowData.app,
                        dataRef: "app",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Ordered By",
                        field: (rowData) => (staff && (staff[rowData.staff] || rowData.staff === -1) && <StaffChip staff={staff[rowData.staff]} missingText={rowData.staff === -1 ? 'E-commerce' : null } />) || "-",
                        dataRef: "staff",
                        sizeToContent: true,
                        fieldFormat: "staff",
                    },
                    {
                        heading: "Under Review By",
                        field: (rowData) => (staff && staff[rowData.rev] && <StaffChip staff={staff[rowData.rev]} />) || "-",
                        dataRef: "rev",
                        sizeToContent: true,
                        fieldFormat: "staff",
                    },
                    {
                        actions: (rowData) => [
                            { name: "View Sales Order", icon: "binoculars", link: `/sales/${rowData.id}`, doubleClick: !access.processAa },
                            { name: "Preview Sales Order", icon: "eye", onClick: () => deployPreview(dispatch, 'order', rowData.id) },
                            { name: rowData.reviewId > 0 ? "Remove Review Flag" : "Mark as Under Review", icon: rowData.reviewId > 0 ? "user-slash" : "user", onClick: (rowData) => handleInProgressUser(callback, dispatch, rowData.id, rowData.reviewId) },
                            { name: "Approve / Decline Order", icon: "check", link: `/sales/process/${rowData.id}`, disabled: !access.processAa, doubleClick: access.processAa },
                        ],
                    },
                ];

            case "Awaiting Authorisation":
                return [
                    {
                        heading: "Order Number",
                        field: (rowData) => (
                            <>
                                <Typography variant="body2" className="fw-400">
                                    {rowData.ref}
                                </Typography>
                                <Typography variant="caption">{rowData.crf}</Typography>
                            </>
                        ),
                        dataRef: "ref",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Order Date",
                        field: (rowData) => rowData.created,
                        fieldFormat: "date",
                        dataRef: "created",
                        sizeToContent: true,
                    },
                    {
                        heading: "Customer",
                        field: (rowData) => rowData.customer,
                        dataRef: "customer",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Net Total",
                        field: (rowData) => rowData.net,
                        fieldFormat: "currency",
                        dataRef: "net",
                        alignment: "right",
                        sizeToContent: true,
                    },
                    {
                        heading: "Gross Total",
                        field: (rowData) => rowData.gross,
                        dataRef: "gross",
                        fieldFormat: "currency",
                        alignment: "right",
                        sizeToContent: true,
                    },
                    {
                        heading: "Status",
                        field: (rowData) => <StatusChip status={rowData.cos} />,
                        dataRef: "cos",
                        sizeToContent: true,
                    },
                    {
                        actions: (rowData) => [
                            { name: "View Sales Order", icon: "binoculars", link: `/sales/${rowData.id}`, doubleClick: !access.processAu },
                            { name: "Preview Sales Order", icon: "eye", onClick: () => deployPreview(dispatch, 'order', rowData.id) },
                            { name: "Generate Card Order", icon: "check", link: `/sales/process/${rowData.id}`, disabled: !access.processAu, doubleClick: access.processAu },
                        ],
                    },
                ];

            case "Awaiting Payment":
                return [
                    {
                        heading: "SO Number",
                        field: (rowData) => rowData.ref,
                        dataRef: "ref",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Order Date",
                        field: (rowData) => rowData.created,
                        fieldFormat: "date",
                        dataRef: "created",
                        sizeToContent: true,
                    },
                    {
                        heading: "Customer",
                        field: (rowData) => rowData.customer,
                        dataRef: "customer",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Account Number",
                        field: (rowData) => rowData.acc,
                        dataRef: "acc",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Customer Reference",
                        field: (rowData) => rowData.crf || "-",
                        truncate: true,
                        dataRef: "crf",
                        main: true,
                        sizeToContent: true,
                    },
                    // {
                    //     heading: 'Net Total',
                    //     field: rowData => rowData.net,
                    //     fieldFormat: 'currency',
                    //     dataRef: 'net',
                    //     alignment: 'right',
                    //     sizeToContent: true
                    // },
                    {
                        heading: "Gross Total",
                        field: (rowData) => rowData.gross,
                        dataRef: "gross",
                        fieldFormat: "currency",
                        alignment: "right",
                        sizeToContent: true,
                    },
                    {
                        heading: "Total Paid",
                        field: (rowData) => rowData.pay,
                        fieldFormat: "currency",
                        dataRef: "pay",
                        alignment: "right",
                        sizeToContent: true,
                    },
                    {
                        heading: "Cleared",
                        field: (rowData) => rowData.cle,
                        fieldFormat: "boolean",
                        alignment: "center",
                        dataRef: "cle",
                        sizeToContent: true,
                    },
                    {
                        heading: "Card",
                        field: (rowData) => rowData.cp,
                        fieldFormat: "boolean",
                        alignment: "center",
                        dataRef: "cp",
                        sizeToContent: true,
                    },
                    {
                        heading: "Cash",
                        field: (rowData) => rowData.up,
                        fieldFormat: "boolean",
                        alignment: "center",
                        dataRef: "up",
                        sizeToContent: true,
                    },
                    {
                        heading: "Proforma",
                        field: (rowData) => rowData.pp,
                        fieldFormat: "boolean",
                        alignment: "center",
                        dataRef: "pp",
                        sizeToContent: true,
                    },
                    {
                        heading: "Proforma Sent",
                        field: (rowData) => rowData.ps,
                        fieldFormat: "boolean",
                        alignment: "center",
                        dataRef: "ps",
                        sizeToContent: true,
                    },
                    {
                        actions: (rowData) => [
                            { name: "View Sales Order", icon: "binoculars", link: `/sales/${rowData.id}`, doubleClick: !access.processAp },
                            { name: "Preview Sales Order", icon: "eye", onClick: () => deployPreview(dispatch, 'order', rowData.id) },
                            { name: "Process Payment", icon: "check", link: `/sales/process/${rowData.id}`, disabled: !access.processAp, doubleClick: access.processAp },
                        ],
                    },
                ];

            case "Processing":
                return [
                    {
                        heading: "SO Number",
                        field: (rowData) => rowData.ref,
                        dataRef: "ref",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Order Date",
                        field: (rowData) => rowData.created,
                        fieldFormat: "date",
                        dataRef: "created",
                        sizeToContent: true,
                    },
                    {
                        heading: "Customer",
                        field: (rowData) => rowData.customer,
                        dataRef: "customer",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Account Number",
                        field: (rowData) => rowData.acc,
                        dataRef: "acc",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Customer Reference",
                        field: (rowData) => rowData.crf || "-",
                        truncate: true,
                        dataRef: "crf",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Net Total",
                        field: (rowData) => rowData.net,
                        fieldFormat: "currency",
                        dataRef: "net",
                        alignment: "right",
                        sizeToContent: true,
                    },
                    {
                        heading: "Gross Total",
                        field: (rowData) => rowData.gross,
                        dataRef: "gross",
                        fieldFormat: "currency",
                        alignment: "right",
                        sizeToContent: true,
                    },
                    {
                        actions: (rowData) => [
                            { name: "View Sales Order", icon: "binoculars", link: `/sales/${rowData.id}`, doubleClick: !access.processPo },
                            { name: "Preview Sales Order", icon: "eye", onClick: () => deployPreview(dispatch, 'order', rowData.id) },
                            { name: "Process Sales Order", icon: "check", link: `/sales/process/${rowData.id}`, disabled: !access.processPo, doubleClick: access.processPo },
                        ],
                    },
                ];

            case "Awaiting Allocation":
                return [
                    {
                        heading: "RAG",
                        field: (rowData) => (
                            <Box width={20} textAlign="center">
                                <FAIcon icon="circle" type="duo" size={17.5} noMargin className={(rowData.despRag === "A" && "textWarning") || (rowData.despRag === "R" && "textError") || "textSuccess"} />
                            </Box>
                        ),
                        dataRef: "despRag",
                        sizeToContent: true,
                        alignment: "center",
                        // cellProps: rowData => ({
                        //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                        // })
                    },
                    {
                        heading: "SO Number",
                        field: (rowData) => rowData.ref,
                        dataRef: "ref",
                        main: true,
                        // cellProps: rowData => ({
                        //     className: rowData.allocated === 0 ? 'textError' : ( moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined)
                        // })
                    },
                    {
                        heading: "Order Date",
                        field: (rowData) => rowData.created,
                        fieldFormat: "date",
                        dataRef: "created",
                        // cellProps: rowData => ({
                        //     className: rowData.allocated === 0 ? 'textError' : ( moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined)
                        // })
                    },
                    {
                        heading: "Customer",
                        field: (rowData) => rowData.customer,
                        dataRef: "customer",
                        main: true,
                        // cellProps: rowData => ({
                        //     className: rowData.allocated === 0 ? 'textError' : ( moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined)
                        // })
                    },
                    {
                        heading: "Account Number",
                        field: (rowData) => rowData.acc,
                        dataRef: "acc",
                        main: true,
                        sizeToContent: true,
                        // cellProps: rowData => ({
                        //     className: rowData.allocated === 0 ? 'textError' : ( moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined)
                        // })
                    },
                    {
                        heading: "Customer Reference",
                        field: (rowData) => rowData.crf || "-",
                        truncate: true,
                        dataRef: "crf",
                        main: true,
                        sizeToContent: true,
                        // cellProps: rowData => ({
                        //     className: rowData.allocated === 0 ? 'textError' : ( moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined)
                        // })
                    },
                    {
                        heading: "Net Total",
                        field: (rowData) => rowData.net,
                        fieldFormat: "currency",
                        dataRef: "net",
                        alignment: "right",
                        // cellProps: rowData => ({
                        //     className: rowData.allocated === 0 ? 'textError' : ( moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined)
                        // })
                    },
                    {
                        heading: "Expected Despatch Date",
                        field: (rowData) => rowData.despatch,
                        fieldFormat: "date",
                        dataRef: "despatch",
                        // cellProps: rowData => ({
                        //     className: rowData.allocated === 0 ? 'textError' : ( moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined)
                        // })
                    },
                    {
                        actions: (rowData) => [
                            { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}`, doubleClick: true },
                            { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) },
                        ],
                    },
                ];
            case "Awaiting Stock":
                if (warehouse) {
                    return [
                        {
                            heading: "SO Number",
                            field: (rowData) => rowData.ref,
                            dataRef: "ref",
                            main: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Order Date",
                            field: (rowData) => rowData.created,
                            fieldFormat: "date",
                            dataRef: "created",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Customer",
                            field: (rowData) => rowData.customer,
                            dataRef: "customer",
                            main: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Account Number",
                            field: (rowData) => rowData.acc,
                            dataRef: "acc",
                            main: true,
                            sizeToContent: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Customer Reference",
                            field: (rowData) => rowData.crf || "-",
                            truncate: true,
                            dataRef: "crf",
                            main: true,
                            sizeToContent: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Supplier",
                            field: (rowData) => (rowData.spplr?.length === 1 ? rowData.spplr[0].nm : rowData.spplr?.length > 0 ? <InsightChip icon="info-circle" label={rowData.spplr.length} className="fw-300" /> : "-"),
                            dataRef: "spplrL",
                            sizeToContent: true,
                            tooltip: (rowData) =>
                                rowData.spplr?.length > 0
                                    ? _.map(rowData.spplr, (spplr, idx) => (
                                          <Typography variant="body2" key={idx} gutterBottom>
                                              {spplr.nm}
                                          </Typography>
                                      ))
                                    : null,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "PO Number",
                            field: (rowData) => (rowData.pod?.length === 1 ? rowData.pod[0].ref : rowData.pod?.length > 0 ? <InsightChip icon="info-circle" label={rowData.pod.length} className="fw-300" /> : "-"),
                            dataRef: "podL",
                            sizeToContent: true,
                            tooltip: (rowData) =>
                                rowData.pod?.length > 0
                                    ? _.map(rowData.pod, (pod, idx) => (
                                          <Typography variant="body2" key={idx} gutterBottom>
                                              {pod.ref}
                                          </Typography>
                                      ))
                                    : null,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Estimated Despatch Date",
                            field: (rowData) => rowData.despatch,
                            fieldFormat: "date",
                            dataRef: "despatch",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            actions: (rowData) => [
                                { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}`, doubleClick: true },
                                { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) },
                            ],
                        },
                    ];
                } else {
                    return [
                        {
                            heading: "RAG",
                            field: (rowData) => (
                                <Box width={20} textAlign="center">
                                    <FAIcon icon="circle" type="duo" size={17.5} noMargin className={(rowData.despRag === "A" && "textWarning") || (rowData.despRag === "R" && "textError") || "textSuccess"} />
                                </Box>
                            ),
                            dataRef: "despRag",
                            sizeToContent: true,
                            alignment: "center",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "SO Number",
                            field: (rowData) => rowData.ref,
                            dataRef: "ref",
                            main: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Order Date",
                            field: (rowData) => rowData.created,
                            fieldFormat: "date",
                            dataRef: "created",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Customer",
                            field: (rowData) => rowData.customer,
                            dataRef: "customer",
                            main: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Account Number",
                            field: (rowData) => rowData.acc,
                            dataRef: "acc",
                            main: true,
                            sizeToContent: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Customer Reference",
                            field: (rowData) => rowData.crf || "-",
                            truncate: true,
                            dataRef: "crf",
                            main: true,
                            sizeToContent: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Net Total",
                            field: (rowData) => rowData.net,
                            fieldFormat: "currency",
                            dataRef: "net",
                            alignment: "right",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Supplier",
                            field: (rowData) => (rowData.spplr?.length === 1 ? rowData.spplr[0].nm : rowData.spplr?.length > 0 ? <InsightChip icon="info-circle" label={rowData.spplr.length} className="fw-300" /> : "-"),
                            dataRef: "spplrL",
                            sizeToContent: true,
                            tooltip: (rowData) =>
                                rowData.spplr?.length > 0
                                    ? _.map(rowData.spplr, (spplr, idx) => (
                                          <Typography variant="body2" key={idx} gutterBottom>
                                              {spplr.nm}
                                          </Typography>
                                      ))
                                    : null,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "PO Number",
                            field: (rowData) => (rowData.pod?.length === 1 ? rowData.pod[0].ref : rowData.pod?.length > 0 ? <InsightChip icon="info-circle" label={rowData.pod.length} className="fw-300" /> : "-"),
                            dataRef: "podL",
                            sizeToContent: true,
                            tooltip: (rowData) =>
                                rowData.pod?.length > 0
                                    ? _.map(rowData.pod, (pod, idx) => (
                                          <Typography variant="body2" key={idx} gutterBottom>
                                              {pod.ref}
                                          </Typography>
                                      ))
                                    : null,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Estimated Despatch Date",
                            field: (rowData) => rowData.despatch,
                            fieldFormat: "date",
                            dataRef: "despatch",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            actions: (rowData) => [
                                { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}`, doubleClick: true },
                                { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) },
                            ],
                        },
                    ];
                }

            case "Awaiting Picking":
                if (warehouse) {
                    return [
                        {
                            heading: "SO Number",
                            field: (rowData) => rowData.ref,
                            dataRef: "ref",
                            main: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Order Date",
                            field: (rowData) => rowData.created,
                            fieldFormat: "date",
                            dataRef: "created",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Customer",
                            field: (rowData) => rowData.customer,
                            dataRef: "customer",
                            main: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Account Number",
                            field: (rowData) => rowData.acc,
                            dataRef: "acc",
                            main: true,
                            sizeToContent: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Customer Reference",
                            field: (rowData) => rowData.crf || "-",
                            truncate: true,
                            dataRef: "crf",
                            main: true,
                            sizeToContent: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Despatch Date",
                            field: (rowData) => rowData.despatch,
                            fieldFormat: "date",
                            dataRef: "despatch",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            actions: (rowData) => [
                                // { name: 'Pick Delivery', icon: 'check',  link: `/warehouse/process/${rowData.id}`, doubleClick: true }
                                { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}`, doubleClick: true },
                                { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) },
                            ],
                        },
                    ];
                } else {
                    return [
                        {
                            heading: "RAG",
                            field: (rowData) => (
                                <Box width={20} textAlign="center">
                                    <FAIcon icon="circle" type="duo" size={17.5} noMargin className={(rowData.despRag === "A" && "textWarning") || (rowData.despRag === "R" && "textError") || "textSuccess"} />
                                </Box>
                            ),
                            dataRef: "despRag",
                            sizeToContent: true,
                            alignment: "center",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "SO Number",
                            field: (rowData) => rowData.ref,
                            dataRef: "ref",
                            main: true,
                            // cellProps: rowData => ({
                            //     className: rowData.allocated === 0 ? 'textError' : ( moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined)
                            // })
                        },
                        {
                            heading: "Order Date",
                            field: (rowData) => rowData.created,
                            fieldFormat: "date",
                            dataRef: "created",
                            // cellProps: rowData => ({
                            //     className: rowData.allocated === 0 ? 'textError' : ( moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined)
                            // })
                        },
                        {
                            heading: "Customer",
                            field: (rowData) => rowData.customer,
                            dataRef: "customer",
                            main: true,
                            // cellProps: rowData => ({
                            //     className: rowData.allocated === 0 ? 'textError' : ( moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined)
                            // })
                        },
                        {
                            heading: "Account Number",
                            field: (rowData) => rowData.acc,
                            dataRef: "acc",
                            main: true,
                            sizeToContent: true,
                            // cellProps: rowData => ({
                            //     className: rowData.allocated === 0 ? 'textError' : ( moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined)
                            // })
                        },
                        {
                            heading: "Customer Reference",
                            field: (rowData) => rowData.crf || "-",
                            truncate: true,
                            dataRef: "crf",
                            main: true,
                            sizeToContent: true,
                            // cellProps: rowData => ({
                            //     className: rowData.allocated === 0 ? 'textError' : ( moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined)
                            // })
                        },
                        {
                            heading: "Net Total",
                            field: (rowData) => rowData.net,
                            fieldFormat: "currency",
                            dataRef: "net",
                            alignment: "right",
                            // cellProps: rowData => ({
                            //     className: rowData.allocated === 0 ? 'textError' : ( moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined)
                            // })
                        },
                        {
                            heading: "Expected Despatch Date",
                            field: (rowData) => rowData.despatch,
                            fieldFormat: "date",
                            dataRef: "despatch",
                            // cellProps: rowData => ({
                            //     className: rowData.allocated === 0 ? 'textError' : ( moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined)
                            // })
                        },
                        {
                            actions: (rowData) => [
                                { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}`, doubleClick: true },
                                { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) },
                            ],
                        },
                    ];
                }

            case "Awaiting Check":
                if (warehouse) {
                    return [
                        {
                            heading: "SO Number",
                            field: (rowData) => rowData.ref,
                            dataRef: "ref",
                            main: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Order Date",
                            field: (rowData) => rowData.created,
                            fieldFormat: "date",
                            dataRef: "created",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Customer",
                            field: (rowData) => rowData.customer,
                            dataRef: "customer",
                            truncate: true,
                        },
                        {
                            heading: "Account Number",
                            field: (rowData) => rowData.acc,
                            dataRef: "acc",
                            main: true,
                            sizeToContent: true,
                        },
                        {
                            heading: "Customer Reference",
                            field: (rowData) => rowData.crf || "-",
                            truncate: true,
                            dataRef: "crf",
                            main: true,
                            sizeToContent: true,
                        },
                        {
                            heading: "Expected Despatch Date",
                            field: (rowData) => rowData.despatch,
                            fieldFormat: "date",
                            dataRef: "despatch",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Picked By",
                            field: (rowData) => (staff && staff[rowData.psid] && <StaffChip staff={staff[rowData.psid]} />) || "-",
                            dataRef: "psid",
                            truncate: true,
                            fieldFormat: "staff",
                        },
                        {
                            actions: (rowData) => [
                                // { name: 'Despatch Delivery', icon: 'check',  link: `/warehouse/process/${rowData.id}`, doubleClick: true }
                                { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}`, doubleClick: true},
                                { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) },
                            ],
                        },
                    ];
                } else {
                    return [
                        {
                            heading: "SO Number",
                            field: (rowData) => rowData.ref,
                            dataRef: "ref",
                            main: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Picked By",
                            field: (rowData) => (staff && staff[rowData.psid] && <StaffChip staff={staff[rowData.psid]} />) || "-",
                            dataRef: "staff",
                            truncate: true,
                            fieldFormat: "staff",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Order Date",
                            field: (rowData) => rowData.created,
                            fieldFormat: "date",
                            dataRef: "created",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Customer",
                            field: (rowData) => rowData.customer,
                            dataRef: "customer",
                            truncate: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Account Number",
                            field: (rowData) => rowData.acc,
                            dataRef: "acc",
                            main: true,
                            sizeToContent: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Customer Reference",
                            field: (rowData) => rowData.crf || "-",
                            truncate: true,
                            dataRef: "crf",
                            main: true,
                            sizeToContent: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Net Total",
                            field: (rowData) => rowData.net,
                            fieldFormat: "currency",
                            dataRef: "net",
                            alignment: "right",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Expected Despatch Date",
                            field: (rowData) => rowData.despatch,
                            fieldFormat: "date",
                            dataRef: "despatch",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            actions: (rowData) => [
                                { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}`, doubleClick: true },
                                { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) },
                            ],
                        },
                    ];
                }
            case "Awaiting Technical":
                if (warehouse) {
                    return [
                        {
                            heading: "SO Number",
                            field: (rowData) => rowData.ref,
                            dataRef: "ref",
                            main: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Order Date",
                            field: (rowData) => rowData.created,
                            fieldFormat: "date",
                            dataRef: "created",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Customer",
                            field: (rowData) => rowData.customer,
                            dataRef: "customer",
                            truncate: true,
                        },
                        {
                            heading: "Account Number",
                            field: (rowData) => rowData.acc,
                            dataRef: "acc",
                            main: true,
                            sizeToContent: true,
                        },
                        {
                            heading: "Customer Reference",
                            field: (rowData) => rowData.crf || "-",
                            truncate: true,
                            dataRef: "crf",
                            main: true,
                            sizeToContent: true,
                        },
                        {
                            heading: "Expected Despatch Date",
                            field: (rowData) => rowData.despatch,
                            fieldFormat: "date",
                            dataRef: "despatch",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Picked By",
                            field: (rowData) => (staff && staff[rowData.psid] && <StaffChip staff={staff[rowData.psid]} />) || "-",
                            dataRef: "psid",
                            truncate: true,
                            fieldFormat: "staff",
                        },
                        {
                            actions: (rowData) => [
                                // { name: 'Despatch Delivery', icon: 'check',  link: `/warehouse/process/${rowData.id}`, doubleClick: true }
                                { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}`, doubleClick: true },
                                { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) },
                            ],
                        },
                    ];
                } else {
                    return [
                        {
                            heading: "SO Number",
                            field: (rowData) => rowData.ref,
                            dataRef: "ref",
                            main: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Picked By",
                            field: (rowData) => (staff && staff[rowData.psid] && <StaffChip staff={staff[rowData.psid]} />) || "-",
                            dataRef: "staff",
                            truncate: true,
                            fieldFormat: "staff",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Order Date",
                            field: (rowData) => rowData.created,
                            fieldFormat: "date",
                            dataRef: "created",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Customer",
                            field: (rowData) => rowData.customer,
                            dataRef: "customer",
                            truncate: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Account Number",
                            field: (rowData) => rowData.acc,
                            dataRef: "acc",
                            main: true,
                            sizeToContent: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Customer Reference",
                            field: (rowData) => rowData.crf || "-",
                            truncate: true,
                            dataRef: "crf",
                            main: true,
                            sizeToContent: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Net Total",
                            field: (rowData) => rowData.net,
                            fieldFormat: "currency",
                            dataRef: "net",
                            alignment: "right",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Expected Despatch Date",
                            field: (rowData) => rowData.despatch,
                            fieldFormat: "date",
                            dataRef: "despatch",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            actions: (rowData) => [
                                { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}`, doubleClick: true},
                                { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) },
                            ],
                        },
                    ];
                }
            case "Awaiting Despatch":
                if (warehouse) {
                    return [
                        {
                            heading: "SO Number",
                            field: (rowData) => rowData.ref,
                            dataRef: "ref",
                            main: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Order Date",
                            field: (rowData) => rowData.created,
                            fieldFormat: "date",
                            dataRef: "created",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Customer",
                            field: (rowData) => rowData.customer,
                            dataRef: "customer",
                            truncate: true,
                        },
                        {
                            heading: "Account Number",
                            field: (rowData) => rowData.acc,
                            dataRef: "acc",
                            main: true,
                            sizeToContent: true,
                        },
                        {
                            heading: "Customer Reference",
                            field: (rowData) => rowData.crf || "-",
                            truncate: true,
                            dataRef: "crf",
                            main: true,
                            sizeToContent: true,
                        },
                        {
                            heading: "Expected Despatch Date",
                            field: (rowData) => rowData.despatch,
                            fieldFormat: "date",
                            dataRef: "despatch",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Picked By",
                            field: (rowData) => (staff && staff[rowData.psid] && <StaffChip staff={staff[rowData.psid]} />) || "-",
                            dataRef: "psid",
                            truncate: true,
                            fieldFormat: "staff",
                        },
                        {
                            heading: "Checked By",
                            field: (rowData) => (staff && staff[rowData.csid] && <StaffChip staff={staff[rowData.csid]} />) || "-",
                            dataRef: "csid",
                            truncate: true,
                            fieldFormat: "staff",
                        },
                        {
                            actions: (rowData) => [
                                // { name: 'Despatch Delivery', icon: 'check',  link: `/warehouse/process/${rowData.id}`, doubleClick: true }
                                { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}`, doubleClick: true },
                                { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) },
                            ],
                        },
                    ];
                } else {
                    return [
                        {
                            heading: "SO Number",
                            field: (rowData) => rowData.ref,
                            dataRef: "ref",
                            main: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Picked By",
                            field: (rowData) => (staff && staff[rowData.psid] && <StaffChip staff={staff[rowData.psid]} />) || "-",
                            dataRef: "staff",
                            truncate: true,
                            fieldFormat: "staff",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Checked By",
                            field: (rowData) => (staff && staff[rowData.csid] && <StaffChip staff={staff[rowData.csid]} />) || "-",
                            dataRef: "csid",
                            truncate: true,
                            fieldFormat: "staff",
                        },
                        {
                            heading: "Order Date",
                            field: (rowData) => rowData.created,
                            fieldFormat: "date",
                            dataRef: "created",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Customer",
                            field: (rowData) => rowData.customer,
                            dataRef: "customer",
                            truncate: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Account Number",
                            field: (rowData) => rowData.acc,
                            dataRef: "acc",
                            main: true,
                            sizeToContent: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Customer Reference",
                            field: (rowData) => rowData.crf || "-",
                            truncate: true,
                            dataRef: "crf",
                            main: true,
                            sizeToContent: true,
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Net Total",
                            field: (rowData) => rowData.net,
                            fieldFormat: "currency",
                            dataRef: "net",
                            alignment: "right",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            heading: "Expected Despatch Date",
                            field: (rowData) => rowData.despatch,
                            fieldFormat: "date",
                            dataRef: "despatch",
                            // cellProps: rowData => ({
                            //     className: moment(moment(rowData.despatch, "DD/MM/YYYY").format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD')) ? 'textError' : undefined
                            // })
                        },
                        {
                            actions: (rowData) => [
                                { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}`, doubleClick: true },
                                { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) },
                            ],
                        },
                    ];
                }

            case "Ready for Collection":
                if (warehouse) {
                    return [
                        {
                            heading: "SO Number",
                            field: (rowData) => rowData.ref,
                            dataRef: "ref",
                            main: true,
                        },
                        {
                            heading: "Order Date",
                            field: (rowData) => rowData.created,
                            fieldFormat: "date",
                            dataRef: "created",
                        },
                        {
                            heading: "Customer",
                            field: (rowData) => rowData.customer,
                            dataRef: "customer",
                            truncate: true,
                        },
                        {
                            heading: "Account Number",
                            field: (rowData) => rowData.acc,
                            dataRef: "acc",
                        },
                        {
                            heading: "Customer Reference",
                            field: (rowData) => rowData.crf || "-",
                            truncate: true,
                            dataRef: "crf",
                        },
                        {
                            actions: (rowData) => [{ name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}` }],
                        },
                    ];
                } else {
                    return [
                        {
                            heading: "SO Number",
                            field: (rowData) => rowData.ref,
                            dataRef: "ref",
                            main: true,
                        },
                        {
                            heading: "Picked By",
                            field: (rowData) => (staff && staff[rowData.psid] && <StaffChip staff={staff[rowData.psid]} />) || "-",
                            dataRef: "psid",
                            truncate: true,
                            fieldFormat: "staff",
                        },
                        {
                            heading: "Checked By",
                            field: (rowData) => (staff && staff[rowData.csid] && <StaffChip staff={staff[rowData.csid]} />) || "-",
                            dataRef: "csid",
                            truncate: true,
                            fieldFormat: "staff",
                        },
                        {
                            heading: "Order Date",
                            field: (rowData) => rowData.created,
                            fieldFormat: "date",
                            dataRef: "created",
                        },
                        {
                            heading: "Customer",
                            field: (rowData) => rowData.customer,
                            dataRef: "customer",
                            truncate: true,
                        },
                        {
                            heading: "Account Number",
                            field: (rowData) => rowData.acc,
                            dataRef: "acc",
                            main: true,
                            sizeToContent: true,
                        },
                        {
                            heading: "Customer Reference",
                            field: (rowData) => rowData.crf || "-",
                            truncate: true,
                            dataRef: "crf",
                            main: true,
                            sizeToContent: true,
                        },
                        {
                            heading: "Net Total",
                            field: (rowData) => rowData.net,
                            fieldFormat: "currency",
                            dataRef: "net",
                            alignment: "right",
                        },
                        {
                            actions: (rowData) => [
                                { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}`, doubleClick: true },
                                { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) },
                            ],
                        },
                    ];
                }

            case "Awaiting Supplier":
                return [
                    {
                        heading: "Confirmed",
                        field: (rowData) => rowData.allocated,
                        fieldFormat: "boolean",
                        dataRef: "allocated",
                        sizeToContent: true,
                        alignment: "center",
                    },
                    {
                        heading: "SO Number",
                        field: (rowData) => rowData.ref,
                        dataRef: "ref",
                        main: true,
                        // cellProps: rowData => ({
                        //     className: rowData.allocated === 0 ? 'textError' : undefined
                        // })
                    },
                    {
                        heading: "Order Date",
                        field: (rowData) => rowData.created,
                        fieldFormat: "date",
                        dataRef: "created",
                        // cellProps: rowData => ({
                        //     className: rowData.allocated === 0 ? 'textError' : undefined
                        // })
                    },
                    {
                        heading: "Customer",
                        field: (rowData) => rowData.customer,
                        dataRef: "customer",
                        truncate: true,
                        // cellProps: rowData => ({
                        //     className: rowData.allocated === 0 ? 'textError' : undefined
                        // })
                    },
                    {
                        heading: "Account Number",
                        field: (rowData) => rowData.acc,
                        dataRef: "acc",
                        main: true,
                        sizeToContent: true,
                        // cellProps: rowData => ({
                        //     className: rowData.allocated === 0 ? 'textError' : undefined
                        // })
                    },
                    {
                        heading: "Customer Reference",
                        field: (rowData) => rowData.crf || "-",
                        truncate: true,
                        dataRef: "crf",
                        main: true,
                        sizeToContent: true,
                        // cellProps: rowData => ({
                        //     className: rowData.allocated === 0 ? 'textError' : undefined
                        // })
                    },
                    {
                        heading: "Net Total",
                        field: (rowData) => rowData.net,
                        fieldFormat: "currency",
                        dataRef: "net",
                        alignment: "right",
                        // cellProps: rowData => ({
                        //     className: rowData.allocated === 0 ? 'textError' : undefined
                        // })
                    },
                    {
                        heading: "Supplier",
                        field: (rowData) => rowData.supp ?? "-",
                        dataRef: "supp",
                        main: true,
                        sizeToContent: true,
                        // cellProps: rowData => ({
                        //     className: rowData.allocated === 0 ? 'textError' : undefined
                        // })
                    },
                    {
                        heading: "PO Number",
                        field: (rowData) => `${rowData.poRef ?? "-"}`,
                        dataRef: "poRef",
                        main: true,
                        sizeToContent: true,
                        // cellProps: rowData => ({
                        //     className: rowData.allocated === 0 ? 'textError' : undefined
                        // })
                    },
                    {
                        heading: "Despatch Date",
                        field: (rowData) => rowData.despatch,
                        fieldFormat: "date",
                        dataRef: "despatch",
                        // cellProps: rowData => ({
                        //     className: rowData.allocated === 0 ? 'textError' : undefined
                        // })
                    },
                    {
                        heading: "Check Date",
                        field: (rowData) => rowData.check,
                        fieldFormat: "date",
                        dataRef: "check",
                        cellProps: (rowData) => ({
                            className: moment(moment(rowData.check, "DD/MM/YYYY").format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD")) ? "textError" : undefined,
                        }),
                    },
                    {
                        actions: (rowData) => [
                            { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}`, doubleClick: true },
                            { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) },
                        ],
                    },
                ];

            case "Despatched":
                if (warehouse) {
                    return [
                        {
                            heading: "SO Number",
                            field: (rowData) => rowData.ref,
                            dataRef: "ref",
                            main: true,
                        },
                        {
                            heading: "Order Date",
                            field: (rowData) => rowData.created,
                            fieldFormat: "date",
                            dataRef: "created",
                        },
                        {
                            heading: "Customer",
                            field: (rowData) => rowData.customer,
                            dataRef: "customer",
                            truncate: true,
                            main: true,
                        },
                        {
                            heading: "Account Number",
                            field: (rowData) => rowData.acc,
                            dataRef: "acc",
                            main: true,
                            sizeToContent: true,
                        },
                        {
                            heading: "Customer Reference",
                            field: (rowData) => rowData.crf || "-",
                            truncate: true,
                            dataRef: "crf",
                            main: true,
                            sizeToContent: true,
                        },
                        {
                            heading: "Despatched On",
                            field: (rowData) => rowData.despatched,
                            dataRef: "despatched",
                        },
                        {
                            heading: "Picked By",
                            field: (rowData) => (staff && staff[rowData.psid] && <StaffChip staff={staff[rowData.psid]} />) || "-",
                            dataRef: "psid",
                            truncate: true,
                            fieldFormat: "staff",
                        },
                        {
                            heading: "Checked By",
                            field: (rowData) => (staff && staff[rowData.csid] && <StaffChip staff={staff[rowData.csid]} />) || "-",
                            dataRef: "csid",
                            truncate: true,
                            fieldFormat: "staff",
                        },
                        {
                            actions: (rowData) => [
                                { name: "Track Order", icon: "location", linkExternal: rowData.tt === 'DPD' ? `https://track.dpdlocal.co.uk/search?reference=${rowData.t}&postcode=${rowData.p?.replace?.(" ", "")?.toLowerCase?.()}` : `https://track.dhlparcel.co.uk/?nav=1&postcode=${rowData.p?.replace?.(" ", "%20")?.toLowerCase?.()}&sn=${rowData.t}`, disabled: _.isEmpty(rowData.t) },
                                { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}` },
                                { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) },
                            ],
                        },
                    ];
                } else {
                    return [
                        {
                            heading: "SO Number",
                            field: (rowData) => rowData.ref,
                            dataRef: "ref",
                            main: true,
                        },
                        {
                            heading: "Order Date",
                            field: (rowData) => rowData.created,
                            fieldFormat: "date",
                            dataRef: "created",
                        },
                        {
                            heading: "Customer",
                            field: (rowData) => rowData.customer,
                            dataRef: "customer",
                            truncate: true,
                            main: true,
                        },
                        {
                            heading: "Account Number",
                            field: (rowData) => rowData.acc,
                            dataRef: "acc",
                            main: true,
                            sizeToContent: true,
                        },
                        {
                            heading: "Customer Reference",
                            field: (rowData) => rowData.crf || "-",
                            truncate: true,
                            dataRef: "crf",
                            main: true,
                            sizeToContent: true,
                        },
                        {
                            heading: "Net Items",
                            field: (rowData) => rowData.net,
                            fieldFormat: "currency",
                            dataRef: "net",
                            alignment: "right",
                        },
                        {
                            heading: "Net Carriage",
                            field: (rowData) => rowData.carriage,
                            fieldFormat: "currency",
                            dataRef: "carriage",
                            alignment: "right",
                        },
                        {
                            heading: "Despatch Due",
                            field: (rowData) => rowData.despatch,
                            dataRef: "despatch",
                        },
                        {
                            heading: "Despatched On",
                            field: (rowData) => rowData.despatched,
                            dataRef: "despatched",
                        },
                        {
                            heading: "Status",
                            field: (rowData) => <StatusChip status={rowData.onHold === 1 ? `Pending Release` : `Ready to Invoice`} />,
                            sizeToContent: true,
                        },
                        {
                            actions: (rowData) => [
                                { name: "Track Order", icon: "location", linkExternal: rowData.tt === 'DPD' ? `https://track.dpdlocal.co.uk/search?reference=${rowData.t}&postcode=${rowData.p?.replace?.(" ", "")?.toLowerCase?.()}` : `https://track.dhlparcel.co.uk/?nav=1&postcode=${rowData.p?.replace?.(" ", "%20")?.toLowerCase?.()}&sn=${rowData.t}`, disabled: _.isEmpty(rowData.t) },
                                { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}` },
                                { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) },
                                {
                                    name: "Invoice Delivery",
                                    icon: "check",
                                    disabled: rowData.onHold === 1,
                                    onClick: (rowData) =>
                                        dispatch(
                                            deployConfirmation(`Are you sure you want to invoice sales order delivery #${rowData.ref}?`, () =>
                                                dispatch(
                                                    deployDialog(
                                                        <InvoiceDelivery callback={callback} delivery={rowData.ref} order={rowData.orderId} despatchedOn={rowData.despatched} despatchDue={rowData.despatch} net={rowData.net} carriage={rowData.carriage} od={rowData.id} />,
                                                        true,
                                                        "",
                                                        "standard",
                                                        "xs",
                                                        false,
                                                        true
                                                    )
                                                )
                                            )
                                        ),
                                    doubleClick: true,
                                },
                            ],
                        },
                    ];
                }

            case "Invoiced":
                return [
                    {
                        heading: "SO Number",
                        field: (rowData) => rowData.ref,
                        dataRef: "ref",
                        sizeToContent: true,
                    },
                    {
                        heading: "Order Date",
                        field: (rowData) => rowData.created,
                        fieldFormat: "date",
                        dataRef: "created",
                        sizeToContent: true,
                    },
                    {
                        heading: "Customer",
                        field: (rowData) => rowData.customer,
                        dataRef: "customer",
                        truncate: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Account Number",
                        field: (rowData) => rowData.acc,
                        dataRef: "acc",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Customer Reference",
                        field: (rowData) => rowData.crf || "-",
                        truncate: true,
                        dataRef: "crf",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Gross Total",
                        field: (rowData) => rowData.gross,
                        dataRef: "gross",
                        fieldFormat: "currency",
                        alignment: "right",
                        sizeToContent: true,
                    },
                    {
                        heading: "Despatched On",
                        field: (rowData) => rowData.despatched,
                        dataRef: "despatched",
                        sizeToContent: true,
                    },
                    {
                        heading: "Invoice Number",
                        field: (rowData) => rowData.in ?? "-",
                        dataRef: "in",
                        sizeToContent: true,
                    },
                    {
                        heading: "Payment Due Date",
                        field: (rowData) => rowData.ip,
                        fieldFormat: "date",
                        dataRef: "ip",
                        sizeToContent: true,
                    },
                    {
                        actions: (rowData) => [
                            { name: "Track Order", icon: "location", linkExternal: rowData.tt === 'DPD' ? `https://track.dpdlocal.co.uk/search?reference=${rowData.t}&postcode=${rowData.p?.replace?.(" ", "")?.toLowerCase?.()}` : `https://track.dhlparcel.co.uk/?nav=1&postcode=${rowData.p?.replace?.(" ", "%20")?.toLowerCase?.()}&sn=${rowData.t}`, disabled: _.isEmpty(rowData.t) },
                            { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}`, doubleClick: true },
                            { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) },
                            { name: "View Invoice", icon: "file-pdf", disabled: !rowData.ik && !rowData.legacy, color: rowData.ik || rowData.legacy ? "error" : undefined, onClick: () => (rowData.legacy ? getLegacyOrderDeliveryInvoicePDF(rowData.id) : getPresignedUrl(rowData.ik)) },
                        ],
                    },
                ];

            case "ApprovalDetails":
                return [
                    {
                        heading: "SO Number",
                        field: (rowData) => rowData.ref,
                        dataRef: "ref",
                        main: true,
                        sizeToContent: true,
                        showTotalLabel: true,
                    },
                    {
                        heading: "Order Date",
                        field: (rowData) => rowData.created,
                        fieldFormat: "date",
                        dataRef: "created",
                        sizeToContent: true,
                    },
                    {
                        heading: "Customer Reference",
                        field: rowData => rowData.crf,
                        dataRef: 'crf',
                        sizeToContent: true
                    },
                    {
                        heading: "Net Total",
                        field: (rowData) => rowData.net,
                        fieldFormat: "currency",
                        dataRef: "net",
                        alignment: "right",
                        sizeToContent: true,
                        showTotal: true,
                    },
                    {
                        heading: "Gross Total",
                        field: (rowData) => rowData.gross,
                        fieldFormat: "currency",
                        dataRef: "gross",
                        alignment: "right",
                        sizeToContent: true,
                        showTotal: true,
                    },
                    {
                        heading: "Ordered By",
                        field: (rowData) => (staff && (staff[rowData.staff] || rowData.staff === -1) && <StaffChip staff={staff[rowData.staff]} missingText={rowData.staff === -1 ? 'E-commerce' : null} />) || "-",
                        dataRef: "staff",
                        sizeToContent: true,
                    },
                    {
                        heading: "Status",
                        field: (rowData) => <StatusChip status={rowData.status} />,
                        dataRef: "status",
                        sizeToContent: true,
                    },
                    {
                        actions: (rowData) => [
                            { name: "View Sales Order", icon: "binoculars", link: `/sales/${rowData.id}`, doubleClick: true },
                            { name: "Preview Sales Order", icon: "eye", onClick: () => deployPreview(dispatch, 'order', rowData.id) },
                            { name: "Download Sales Order PDF", icon: "file-pdf", color: "error", onClick: () => (rowData.legacy ? getLegacySalesOrderPDF(rowData.id) : getPresignedUrl(rowData.pdf)) },
                        ],
                    },
                ];

            case "On Hold":
                if (warehouse) {
                    return [
                        {
                            heading: "SO Number",
                            field: (rowData) => rowData.ref,
                            dataRef: "ref",
                            main: true,
                        },
                        {
                            heading: "Order Date",
                            field: (rowData) => rowData.created,
                            fieldFormat: "date",
                            dataRef: "created",
                            sizeToContent: true,
                        },
                        {
                            heading: "Customer",
                            field: (rowData) => rowData.customer,
                            dataRef: "customer",
                            truncate: true,
                        },
                        {
                            heading: "Account Number",
                            field: (rowData) => rowData.acc,
                            dataRef: "acc",
                            main: true,
                            sizeToContent: true,
                        },
                        {
                            heading: "Customer Reference",
                            field: (rowData) => rowData.crf || "-",
                            truncate: true,
                            dataRef: "crf",
                            main: true,
                            sizeToContent: true,
                        },
                        {
                            heading: "Order Status",
                            field: (rowData) => <StatusChip status={rowData.status} />,
                            dataRef: "status",
                        },
                        {
                            heading: "Hold Reason",
                            field: (rowData) => rowData.hRsn,
                            dataRef: "hRsn",
                        },
                        {
                            heading: "Hold Status",
                            field: (rowData) => <StatusChip status={rowData.hSts} />,
                            dataRef: "hSts",
                        },
                        {
                            actions: (rowData) => [
                                { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}`, doubleClick: true },
                                { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) },
                            ],
                        },
                    ];
                } else {
                    return [
                        {
                            heading: "SO Number",
                            field: (rowData) => rowData.ref,
                            dataRef: "ref",
                            main: true,
                        },
                        {
                            heading: "Order Date",
                            field: (rowData) => rowData.created,
                            fieldFormat: "date",
                            dataRef: "created",
                            sizeToContent: true,
                        },
                        {
                            heading: "Customer",
                            field: (rowData) => rowData.customer,
                            dataRef: "customer",
                            truncate: true,
                        },
                        {
                            heading: "Account Number",
                            field: (rowData) => rowData.acc,
                            dataRef: "acc",
                            main: true,
                            sizeToContent: true,
                        },
                        {
                            heading: "Customer Reference",
                            field: (rowData) => rowData.crf || "-",
                            truncate: true,
                            dataRef: "crf",
                            main: true,
                            sizeToContent: true,
                        },
                        {
                            heading: "Gross Total",
                            field: (rowData) => rowData.gross,
                            dataRef: "gross",
                            fieldFormat: "currency",
                            alignment: "right",
                            sizeToContent: true,
                        },
                        {
                            heading: "Order Status",
                            field: (rowData) => <StatusChip status={rowData.status} />,
                            dataRef: "status",
                        },
                        {
                            heading: "Hold Reason",
                            field: (rowData) => rowData.hRsn,
                            dataRef: "hRsn",
                        },
                        {
                            heading: "Hold Status",
                            field: (rowData) => <StatusChip status={rowData.hSts} />,
                            dataRef: "hSts",
                        },
                        {
                            actions: (rowData) => [
                                { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}`, doubleClick: true },
                                { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) },
                            ],
                        },
                    ];
                }
            case "Locked":
                if (warehouse) {
                    return [
                        {
                            heading: "SO Number",
                            field: (rowData) => rowData.ref,
                            dataRef: "ref",
                            main: true,
                        },
                        {
                            heading: "Order Date",
                            field: (rowData) => rowData.created,
                            fieldFormat: "date",
                            dataRef: "created",
                            sizeToContent: true,
                        },
                        {
                            heading: "Customer",
                            field: (rowData) => rowData.customer,
                            dataRef: "customer",
                            truncate: true,
                        },
                        {
                            heading: "Account Number",
                            field: (rowData) => rowData.acc,
                            dataRef: "acc",
                            main: true,
                            sizeToContent: true,
                        },
                        {
                            heading: "Customer Reference",
                            field: (rowData) => rowData.crf || "-",
                            truncate: true,
                            dataRef: "crf",
                            main: true,
                            sizeToContent: true,
                        },
                        {
                            heading: "Order Status",
                            field: (rowData) => <StatusChip status={rowData.status} />,
                            dataRef: "status",
                        },
                        {
                            heading: "Date / Time",
                            field: (rowData) => rowData.hDt,
                            fieldFormat: 'datetime',
                            dataRef: "hDt",
                        },
                        {
                            heading: "Reason",
                            field: (rowData) => rowData.hRsn,
                            dataRef: "hRsn",
                        },
                        {
                            heading: "Locked By",
                            field: (rowData) => <StaffChip staff={staff?.[rowData.hStf ?? -1]} />,
                            dataRef: "hRsn",
                        },
                        // {
                        //     heading: "Status",
                        //     field: (rowData) => <StatusChip status={rowData.hSts} />,
                        //     dataRef: "hSts",
                        // },
                        {
                            actions: (rowData) => [
                                { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}`, doubleClick: true },
                                { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) },
                            ],
                        },
                    ];
                } else {
                    return [
                        {
                            heading: "SO Number",
                            field: (rowData) => rowData.ref,
                            dataRef: "ref",
                            main: true,
                        },
                        {
                            heading: "Order Date",
                            field: (rowData) => rowData.created,
                            fieldFormat: "date",
                            dataRef: "created",
                            sizeToContent: true,
                        },
                        {
                            heading: "Customer",
                            field: (rowData) => rowData.customer,
                            dataRef: "customer",
                            truncate: true,
                        },
                        {
                            heading: "Account Number",
                            field: (rowData) => rowData.acc,
                            dataRef: "acc",
                            main: true,
                            sizeToContent: true,
                        },
                        {
                            heading: "Customer Reference",
                            field: (rowData) => rowData.crf || "-",
                            truncate: true,
                            dataRef: "crf",
                            main: true,
                            sizeToContent: true,
                        },
                        {
                            heading: "Gross Total",
                            field: (rowData) => rowData.gross,
                            dataRef: "gross",
                            fieldFormat: "currency",
                            alignment: "right",
                            sizeToContent: true,
                        },
                        {
                            heading: "Order Status",
                            field: (rowData) => <StatusChip status={rowData.status} />,
                            dataRef: "status",
                        },
                        {
                            heading: "Date / Time",
                            field: (rowData) => rowData.hDt,
                            fieldFormat: 'datetime',
                            dataRef: "hDt",
                        },
                        {
                            heading: "Reason",
                            field: (rowData) => rowData.hRsn,
                            dataRef: "hRsn",
                        },
                        {
                            heading: "Locked By",
                            field: (rowData) => <StaffChip staff={staff?.[rowData.hStf ?? -1]} />,
                            dataRef: "hRsn",
                        },
                        // {
                        //     heading: "Status",
                        //     field: (rowData) => <StatusChip status={rowData.hSts} />,
                        //     dataRef: "hSts",
                        // },
                        {
                            actions: (rowData) => [
                                { name: "View Delivery", icon: "binoculars", link: `/sales/${rowData.orderId}:${rowData.id}`, doubleClick: true },
                                { name: "Preview Delivery", icon: "eye", onClick: () => deployPreview(dispatch, 'od', rowData.orderId, rowData.id) },
                            ],
                        },
                    ];
                }
            default:
                return [
                    {
                        heading: "SO Number",
                        field: (rowData) => rowData.ref,
                        dataRef: "ref",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Order Date",
                        field: (rowData) => rowData.created,
                        fieldFormat: "date",
                        dataRef: "created",
                        sizeToContent: true,
                    },
                    {
                        heading: "Customer",
                        field: (rowData) => rowData.customer,
                        dataRef: "customer",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Account Number",
                        field: (rowData) => rowData.acc,
                        dataRef: "acc",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Customer Reference",
                        field: (rowData) => rowData.crf || "-",
                        truncate: true,
                        dataRef: "crf",
                        main: true,
                        sizeToContent: true,
                    },
                    {
                        heading: "Net Total",
                        field: (rowData) => rowData.net,
                        fieldFormat: "currency",
                        dataRef: "net",
                        alignment: "right",
                        sizeToContent: true,
                    },
                    {
                        heading: "Despatched",
                        field: (rowData) => (rowData.status === "Despatched" || rowData.status === "Invoiced" ? true : false),
                        fieldFormat: "boolean",
                        alignment: "center",
                        sizeToContent: true,
                        dataRef: "des",
                    },
                    {
                        heading: "Invoiced",
                        field: (rowData) => (rowData.status === "Invoiced" ? true : false),
                        fieldFormat: "boolean",
                        alignment: "center",
                        sizeToContent: true,
                        dataRef: "inv",
                    },
                    {
                        heading: "Ordered By",
                        field: (rowData) => (staff && (staff[rowData.staff] || rowData.staff === -1) && <StaffChip staff={staff[rowData.staff]} missingText={rowData.staff === -1 ? 'E-commerce' : null } />) || "-",
                        dataRef: "staff",
                        sizeToContent: true,
                    },
                    {
                        heading: "Status",
                        field: (rowData) => <StatusChip status={rowData.status} />,
                        dataRef: "status",
                        sizeToContent: true,
                    },
                    {
                        actions: (rowData) => [
                            { name: "View Sales Order", icon: "binoculars", link: `/sales/${rowData.id}`, doubleClick: true },
                            { name: "Preview Order", icon: "eye", onClick: () => deployPreview(dispatch, 'order', rowData.orderId ?? rowData.id) },
                            { name: "Download Sales Order PDF", icon: "file-pdf", color: "error", onClick: () => (rowData.legacy ? getLegacySalesOrderPDF(rowData.id) : getPresignedUrl(rowData.pdf)) },
                        ],
                    },
                ];
        }
    }
};

const SearchOrdersTable = ({ access, basic, buildOrders, compact, callback, dashboard, dataLoading, inline, newOrder, noPagination, orderStatus, persistenceId, plainPagination, resetSearch, rows, rowsPerPage = 10, showFullTotals, warehouse }) => {
    const staff = useSelector((state) => state.notifications.status);
    const dispatch = useDispatch();
    return (
        <DataTable
            config={{
                alternatingRowColours: true,
                inline: inline,
                isLoading: dataLoading,
                key: "id",
                pagination: showFullTotals || dashboard || noPagination ? false : true,
                persistenceId: persistenceId ?? null,
                plainPagination: plainPagination ? true : false,
                reset: resetSearch || false,
                rowsPerPage: rowsPerPage,
                showFullTotals: showFullTotals ? true : false,
                options: {
                    dataRef: true,
                    export: {
                        title: (warehouse ? "Warehouse - " : "Sales Orders - ") + (orderStatus || "All"),
                        name: `Orders`,
                        excel: true,
                        pdf: true,
                        print: true,
                    },
                },
            }}
            columns={getColumns(compact ? (orderStatus === "On Hold" ? "CompactOnHold" : "CompactToChase") : orderStatus ? orderStatus : "all", staff, warehouse, access, basic, dispatch, callback, buildOrders)}
            rows={rows}
        />
    );
};

export default SearchOrdersTable;
