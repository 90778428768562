import React from 'react';
import moment from 'moment';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import { Link as RouterLink } from 'react-router-dom';

import Alert from 'Components/Common/Alert/Alert';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import ActionChip from 'Components/Common/Chips/ActionChip';
import API from 'API';
import ActivityLog from 'Components/Common/Activity/ActivityLog';
import ActionMenu from 'Components/Common/Activity/ActionMenu';
import AddCallLogForm from 'Components/Common/Activity/AddCallLogForm';
import AddNoteForm from 'Components/Common/Activity/AddNoteForm';
import CancelOrder from 'Components/Sales/Actions/CancelOrder';
import CardDetailsForm from 'Components/Common/Forms/QuoteOrderForm/CardDetailsForm';
import DataTable from 'Components/Common/DataTables/DataTable';
import DocumentForm from 'Components/Common/Forms/DocumentForm';
import DeliverySummary from 'Components/Sales/Misc/DeliverySummary';
import DeliverySelectionDialog from 'Components/Sales/Misc/DeliverySelectionDialog';
import EmailDialog from 'Components/Communication/Misc/EmailDialog';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';
import Heading from 'Components/Common/Heading/Heading';
import InsightChip from 'Components/Common/Chips/InsightChip';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import logo from 'Assets/Images/no-img.jpg';
import OrderAllocation from 'Components/Sales/Misc/OrderAllocation';
import OrderSummary from 'Components/Sales/Misc/OrderSummary';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import SearchTransactionsTable from 'Components/Accounts/SearchTransactions/SearchTransactionsTable';
import TaskForm from 'Components/Common/Activity/TaskForm';
import Tile from 'Components/Common/Tiles/Tile';
import ViewCallLogs from 'Components/Common/Activity/ViewCallLogs';
import ViewDocuments from 'Components/Common/Activity/ViewDocuments';
import ViewNotes from 'Components/Common/Activity/ViewNotes';
import ViewPage from 'Components/Common/Styles/ViewPage';
import ViewPurchaseOrder from 'Components/Purchases/ViewPurchaseOrder/ViewPurchaseOrder';
import StaffTile from 'Components/Common/Tiles/StaffTile';
import EmailLog from 'Components/Communication/Communication/EmailLog';
import StaffChip from 'Components/Common/Chips/StaffChip';
import ProductStockChip from 'Components/Common/Chips/ProductStockChip';
import StackTable from 'Components/Common/DataTables/StackTable';
import StatusChip from 'Components/Common/Chips/StatusChip';
import ViewActions from 'Components/Common/Activity/ViewActions';
import WildixContext from "Context/WildixContext";
import { deployConfirmation } from 'Redux/Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Redux/Actions/Dialog/Dialog';
import { deploySnackBar } from 'Redux/Actions/SnackBar/SnackBar';
import { SearchApprovalsTable } from 'Components/Sales/Misc/SearchApprovals/SearchApprovalsTable';
import { cardOrderStatus, currencyFormat, handleTabChange, isNumeric, momentFormatDateTime, TabPanel } from 'Functions/MiscFunctions';
import { getPresignedUrl } from 'Functions/S3Functions';
import { pdfFromBase64 } from 'Helpers/PDFHelper';
import { CLOUDFRONT_URL, VIEW_PAGE_APP_BAR_HEIGHT, VIEW_PAGE_OFFSET } from 'Constants';
import { isTablet } from 'Functions/MiscFunctions';
import { isCrushedIce } from 'Functions/AuthFunctions';
import { START_RECORDING, STOP_RECORDING } from 'Helpers/WildixHelper';
import BooleanTile from 'Components/Common/Tiles/BooleanTile';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const getLegacyOrderPDF = orderId => {
    API.get('/sales/' + orderId + '/legacy/pdf')
    .then(result => {
        if(result.data) {
            pdfFromBase64(result.data.pdf, result.data.pdfName);
        }
    })
}

const getLegacyPurchaseOrderPDF = poId => {
    API.get('/purchaseOrders/' + poId + '/legacy/pdf')
    .then(result => {
        if(result.data) {
            pdfFromBase64(result.data.pdf, result.data.pdfName);
        }
    })
}

const getLegacyOrderDeliveryInvoicePDF = deliveryId => {
    API.get('/sales/delivery/' + deliveryId + '/legacy/pdf', { params: { invoice: true }})
    .then(result => {
        if(result.data) {
            pdfFromBase64(result.data.pdf, result.data.pdfName);
        }
    })
}

const initialState = {
    access: {
        cardDb: false,
        deallocateStock: false,
        processOrder:false,
        addCallLog: false,
        addNote: false,
        uploadDoc: false,
        modifyDoc: false,
        deleteDoc: false,
        updateOrder: false,
        reOrder: false,
        viewCustomer: false,
        processApproval: false,
        processPayment: false,
        processAuthorisation: false,
        processProcessing: false,
        cancelOrder: false,
        newRMA: false,
        releaseDelivery: false,
        releaseDeliveryAccounts: false,
        lockDelivery: false,
        cancelDespatch: false,
        emailConfirmation: false,
    },
    initialAccess: {
        cardDb: false,
        deallocateStock: false,
        processOrder:false,
        addCallLog: false,
        addNote: false,
        uploadDoc: false,
        modifyDoc: false,
        deleteDoc: false,
        updateOrder: false,
        reOrder: false,
        viewCustomer: false,
        processApproval: false,
        processPayment: false,
        processAuthorisation: false,
        processProcessing: false,
        cancelOrder: false,
        releaseDelivery: false,
        lockDelivery: false,
        cancelDespatch: false,
        emailConfirmation: false,
    },
    currentTab: 0,
    fulfilmentPercent: 0,
    order: {},
    orderId: 0,
    isLoading: true,
    key: uuidv4(),
    pdf: {
        numPages: '',
        pageNumber: 1,
        scale: isTablet() ? 0.75 : 1.5
    },
    revision: false,
    userIsCrushedIce: isCrushedIce(),
    viewing: {
        list: [],
        process: false,
        update: false,
        pick: false,
        check: false,
        build: false,
        despatch: false
    }
}

class ViewOrder extends React.Component {
    static contextType = WildixContext;
    constructor(props) {
        super(props);
        this.state = initialState;
        this.mainContentArea = React.createRef();
        this.handleTabChange = handleTabChange.bind(this);
        this.poll = false;
    }

    componentDidMount = () => {
        API.multiAccess([
            'card-db',
            'process-order',
            'view-order:add-call',
            'view-order:add-note',     
            'view-order:add-document',     
            'view-order:modify-document',     
            'view-order:delete-document',
            'update-order',
            'replicate-order',
            'deallocate-stock',
            'view-customer',
            'process-order:awaiting-approval',
            'process-order:awaiting-payment',
            'process-order:awaiting-authorisation',
            'process-order:processing',
            'cancel-order',
            'add-rma',
            'rel-locked-orders',
            'rel-locked-orders-accounts',
            'lock-orders',
            'cancel-despatch',
            'view-order:email-confirmation'
        ])
        .then(([
            cardDb,
            processOrder,
            addCallLog, 
            addNote, 
            uploadDoc, 
            modifyDoc, 
            deleteDoc, 
            updateOrder,
            reOrder,
            deallocateStock,
            viewCustomer,
            processApproval,
            processPayment,
            processAuthorisation,
            processProcessing,
            cancelOrder,
            newRMA,
            releaseDelivery,
            releaseDeliveryAccounts,
            lockDelivery,
            cancelDespatch,
            emailConfirmation
        ]) =>  {
            const orderId = this.props.id ? this.props.id : this.props.match.params.id;
            this.setState({ 
                access: {
                    cardDb,
                    processOrder,
                    addCallLog,
                    addNote,
                    uploadDoc,
                    modifyDoc,
                    deleteDoc,
                    updateOrder,
                    reOrder,
                    deallocateStock,
                    viewCustomer,
                    processApproval,
                    processPayment,
                    processAuthorisation,
                    processProcessing,
                    cancelOrder,
                    newRMA,
                    releaseDelivery,
                    releaseDeliveryAccounts,
                    lockDelivery,
                    cancelDespatch,
                    emailConfirmation
                },
                initialAccess: {
                    cardDb,
                    processOrder,
                    addCallLog,
                    addNote,
                    uploadDoc,
                    modifyDoc,
                    deleteDoc,
                    updateOrder,
                    reOrder,
                    deallocateStock,
                    viewCustomer,
                    processApproval,
                    processPayment,
                    processAuthorisation,
                    processProcessing,
                    cancelOrder,
                    newRMA,
                    releaseDelivery,
                    lockDelivery,
                    cancelDespatch,
                    emailConfirmation
                },
                orderId
            }, () => {
                this.poll = setInterval(this.handlePoll, 5000);
                this.loadComponentData(true)
            });
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps.match?.params?.id !== this.props.match?.params?.id) {
            this.setState({
                currentTab: 0,
                orderId: this.props.match.params.id,
            }, () => {            
                this.setState({
                    isLoading: true
                }, () => {
                    this.loadComponentData(true);
                })
            });
        }
    }

    componentWillUnmount = () => {
        if(this.poll) {
            clearInterval(this.poll);
        }
    }

    handlePoll = () => {

        if(!document.hidden) {

            API.post(`/staff/my/view`, 
                { 
                    order: this.state.orderId,
                    type: 'View'
                }, 
                {
                    props: {
                        ignoreErrors: true,
                        noLoading: true
                    }
                }
            )
            .then(res => {
                const { viewing } = this.state;
                if(res?.data) {
                    const list = res.data;
                    if(JSON.stringify(list) !==  JSON.stringify(viewing.list)) {
                        const update = _.some(list, el => el.u === 1);
                        const process = _.some(list, el => el.p === 1);
                        const pick = _.some(list, el => el.pi === 1);
                        const check = _.some(list, el => el.ch === 1);
                        const build = _.some(list, el => el.bu === 1);
                        const despatch = _.some(list, el => el.de === 1);
                        this.setState({
                            viewing: {
                                list,
                                update,
                                process,
                                pick,
                                check,
                                build,
                                despatch
                            }
                        }, () => {
                            if(viewing.process && !process) {
                                const view = _.find(viewing.list, el => el.p === 1)
                                if(this.props.staff[view.i]) {
                                    this.props.deploySnackBar("success", `${this.props.staff[view.i].name} has finished processing this order`);
                                }
                                this.loadComponentData();
                            }
                            if(viewing.update && !update) {
                                const view = _.find(viewing.list, el => el.u === 1)
                                if(this.props.staff[view.i]) {
                                    this.props.deploySnackBar("success", `${this.props.staff[view.i].name} has finished modifying this order`);
                                }
                                this.loadComponentData();
                            }
                            if(viewing.pick && !pick) {
                                const view = _.find(viewing.list, el => el.pi === 1)
                                if(this.props.staff[view.i]) {
                                    this.props.deploySnackBar("success", `${this.props.staff[view.i].name} has finished picking a delivery`);
                                }
                                this.loadComponentData();
                            }
                            if(viewing.check && !check) {
                                const view = _.find(viewing.list, el => el.ch === 1)
                                if(this.props.staff[view.i]) {
                                    this.props.deploySnackBar("success", `${this.props.staff[view.i].name} has finished checking a delivery`);
                                }
                                this.loadComponentData();
                            }
                            if(viewing.build && !build) {
                                const view = _.find(viewing.list, el => el.bu === 1)
                                if(this.props.staff[view.i]) {
                                    this.props.deploySnackBar("success", `${this.props.staff[view.i].name} has finished building a delivery`);
                                }
                                this.loadComponentData();
                            }
                            if(viewing.despatch && !despatch) {
                                const view = _.find(viewing.list, el => el.de === 1)
                                if(this.props.staff[view.i]) {
                                    this.props.deploySnackBar("success", `${this.props.staff[view.i].name} has finished despatching a delivery`);
                                }
                                this.loadComponentData();
                            }
                        })
                    }
                }
            })

        }
    }

    loadComponentData = (initial = false) => { 

        let fromNewOrderPage        = this.props.location?.state?.new ?? false,
            fromCardOrderSearch     = this.props.location?.state?.fromCardOrderSearch ?? false,
            params                  = {},
            orderId                 = this.state.orderId,
            currentTab              = initial ? 0 : this.state.currentTab;
        
        if(initial) {

            if(isNaN(orderId) && orderId.includes(":")) {
                orderId     = this.state.orderId.split(":");
                currentTab  = parseInt(orderId[1]);
                orderId     = parseInt(orderId[0]);
            } else {
                currentTab  = 0;
            }

            if(fromNewOrderPage) {
                this.props.history.replace({state: { new: false }})
                params = {
                    fromNewOrderPage: true
                }
            }

        }

        API.get(`/sales/${orderId}`, { params })
        .then(result =>  {

            if(result?.data && !result?.data?.errors) {

                let   { initialAccess }     =   this.state,
                        isLoading           =   false,
                        fulfilmentPercent   =   0,
                        order               =   result.data,
                        revision            =   result?.data?.order_latest === 1 ? false : true,
                        // payments            =   order.payments,
                        key                 =   uuidv4();

                // if(!_.isEmpty(payments)) {
                //     payments = _.every(payments, el => el.opp_txn_method === "Credit Account" && el.opp_txn_amount === order.order_total_gross) ? [] : payments
                // }

                order.totalPaid = (_.sumBy(_.filter(order.payments, el => el.opp_txn_status === 'Authorised'), el => parseFloat(el.opp_txn_amount))).toFixed(2);
                order.outstanding = parseFloat(parseFloat(order.order_status === 'Cancelled' ? '0.00' : order.order_total_gross) - parseFloat(order.totalPaid)).toFixed(2);
                order.paid = parseFloat(order.totalPaid) === parseFloat(order.order_total_gross);
                // order.payments = payments;
                
                if(initial && currentTab > 0) {
                    currentTab = _.findIndex(order.deliveries, el => el.od_id === currentTab);
                    if(currentTab !== -1) {
                        currentTab = (currentTab + 16)
                    }
                }
                
                if(fromCardOrderSearch) {
                    this.props.history.replace({state: { fromCardOrderSearch: false }})
                    currentTab = order.order_co_required === 0 || (order.order_co_required === 1 && order.order_co_sent === 0) ? currentTab : 15;
                }
                
                let totalDel        = _.filter(order.deliveries, el => el.od_status !== "Cancelled"),
                    nDespatched     = _.filter(totalDel, el => el.od_status !== "Invoiced" && el.od_status !== "Despatched"),
                    outstandingDel  = _.filter(totalDel, el => el.od_status !== "Invoiced"),
                    completedDel    = 0,
                    notDespatched   = 0,
                    progress        = 0;

                totalDel            = _.size(totalDel);
                outstandingDel      = _.size(outstandingDel);
                notDespatched       = _.size(nDespatched);

                if(totalDel > 0) {
                    if(outstandingDel === 0) {
                        fulfilmentPercent = 100;
                    } else {
                        completedDel = (totalDel - outstandingDel);
                        notDespatched = (totalDel - notDespatched);
                        progress = (completedDel + notDespatched);
                        fulfilmentPercent = Math.round((progress / (totalDel * 2)) * 100);
                    }
                }

                this.setState({ 
                    access: {
                        ...this.state.access,
                        deallocateStock: revision ? false : initialAccess.deallocateStock,
                        processOrder:revision ? false : initialAccess.processOrder,
                        addCallLog: revision ? false : initialAccess.addCallLog,
                        addNote: revision ? false : initialAccess.addNote,
                        uploadDoc: revision ? false : initialAccess.uploadDoc,
                        modifyDoc: revision ? false : initialAccess.modifyDoc,
                        deleteDoc: revision ? false : initialAccess.deleteDoc,
                        updateOrder: revision ? false : initialAccess.updateOrder,
                        cancelOrder: revision ? false : initialAccess.cancelOrder
                    },
                    currentTab,
                    fulfilmentPercent,
                    isLoading,
                    key,
                    revision,
                    order,
                    orderId
                }, () => {
                    if(result?.data?.accessToProcess) {
                        if(result.data.order_status === 'Awaiting Approval' || result.data.order_status === 'Awaiting Payment' || result.data.order_status === 'Processing') {
                            const success = () => this.props.history.push(`/sales/process/${result.data.order_id}`)
                            const status = result.data.order_status === 'Processing' ? `awaiting processing` : result.data.order_status.toLowerCase();
                            this.props.deployConfirmation(<><Typography variant="body2" className="fw-400">This sales order is {status}</Typography><Typography variant="body2">Would you like to process it now?</Typography></>, success)
                        } else if(order.outstanding < 0) {
                            this.props.deployConfirmation(<><Typography variant="body2" className="fw-400">There is a refund due to the customer of {currencyFormat.format(Math.abs(order.outstanding))}</Typography><Typography variant="body2">Would you like to see the payment history?</Typography></>, () => this.handleTabChange(10))
                        }
                    } else if(order.outstanding < 0) {
                        this.props.deployConfirmation(<><Typography variant="body2" className="fw-400">There is a refund due to the customer of {currencyFormat.format(Math.abs(order.outstanding))}</Typography><Typography variant="body2">Would you like to see the payment history?</Typography></>, () => this.handleTabChange(10))
                    }
                    
                    if(this.props.pageTitle)
                        this.props.pageTitle([1, "Sales Orders", result?.data?.customer?.cust_company_name, `Sales Order #${result.data.order_reference}`])

                    if(currentTab > 0)
                        window.history.replaceState(null, "RED | Advanced Access", `/sales/${orderId}`)
                })
                
            } else {
                if(this.props.history) {
                    this.props.history.push('/sales');
                }
            }

        })
    }

    handleCrushedIceAction = (action, odId) => {

        const { 
            orderId
        } = this.state;

        API.put(`/crushedIce/salesOrders/${orderId}/deliveries/${odId}/${action}`)
        .then(res => {

            if (res?.data?.success) {

                switch(action) {

                    case 'cancelDespatch':
                        this.handleCrushedIceActionResult("success", "This delivery has been moved back successfully - if this is a warehouse shipment please void the despatch on the DPD portal and discard the despatch note.");
                    break;

                    case 'manualDespatch':
                        this.handleCrushedIceActionResult("success", "This delivery has been despatched successfully - no e-mail was sent to the customer!");
                    break;

                    case 'regenerateInvoicePdf':
                        this.handleCrushedIceActionResult("success", "The invoice has been successfully re-generated and can now be downloaded from the delivery tab");
                    break;

                    default:
                        this.handleCrushedIceActionResult("error", "Error: invalid action type");
                    break;

                }

            } else {
                this.handleCrushedIceActionResult("error", "Something went wrong, please try again...");
            }

        });

    }

    handleCrushedIceActionResult = (snackBarType, snackBarMsg) => {
        
        const { 
            closeDialog, 
            deploySnackBar 
        } = this.props;

        this.loadComponentData();

        deploySnackBar(snackBarType, snackBarMsg);

        closeDialog();

    }

    handleDeallocateOrder = () => {
        const { orderId } = this.state;
        API.post(`/inventory/stock/preallocated`, { id: orderId })
        .then(res => {
            if(res?.data) {
                this.props.deploySnackBar("success", "You have successfully de-allocated this sales order");
                this.loadComponentData();
            }
        })
    }

    handleDeployAddCall = () => this.props.deployDialog(
        <AddCallLogForm
            id={this.state.orderId}
            relation="order"
            toggleDialog={() => { this.loadComponentData(); this.props.closeDialog(); }}
        />, true, "", "standard","xs", false, true);

    handleDeployAddNote = () => this.props.deployDialog(
        <AddNoteForm
            id={this.state.orderId}
            relation="order"
            toggleDialog={() => { this.loadComponentData(); this.props.closeDialog(); }}
            standardOnly
            withFao
        />, true, "", "standard","sm", false, true);

    handleDeployAddTask = () => this.props.deployDialog(
        <TaskForm
            id={this.state.orderId}
            relation="order"
            toggleDialog={() => { this.loadComponentData(); this.props.closeDialog(); }}
        />, true, "", "standard","sm", false, true);

    handleDeployCardDb = () => {
        const { order } = this.state;
        const { loggedInStaff } = this.props;
        window.open(`http://carddb/orderauth.aspx?AccountID=${order?.customer?.cust_account_number ?? ''}&SOPID=${order.order_reference}&UserID=${loggedInStaff.firstName} ${loggedInStaff.lastName}`);
    }

    handleDeployCardDetailsForm = () => {
        const { order } = this.state;
        const { deployDialog } = this.props; 
     
        const callback = () => {
            if(this.context?.dial === null) {
                this.context.useDial(START_RECORDING);
            }
        }

        if(this.context?.dial === null) {
            this.context.useDial(STOP_RECORDING);
        }
        deployDialog(
            <CardDetailsForm 
                callback={callback}
                companyName={order.customer?.cust_company_name}
                sopNumber={order.order_reference}
                amount={order.outstanding}
                registeredAddress={[
                    order.order_address_line_one,   
                    order.order_address_line_two,   
                    order.order_address_line_three, 
                    order.order_address_town,       
                    order.order_address_county,     
                    order.order_address_postcode,  
                    order.order_address_country  
                ].join(", ")}
            />,
            true,
            "",
            "standard",
            "md"
        )
    }

    handleDeployCancelOrder = () => {

        this.loadComponentData();
        
        const {
            closeDialog,
            deployConfirmation,
            deploySnackBar
        } = this.props;

        const { 
            order 
        } = this.state;

        this.props.deployDialog(
            <CancelOrder
                id={this.state.orderId}
                order={this.state.order}
                callback={() => this.loadComponentData(true)}
                closeDialog={closeDialog}
                deployConfirmation={deployConfirmation}
                deploySnackBar={deploySnackBar}
            />, true, "", "standard", _.isEmpty(order.deliveries) ? 'sm' : 'lg', false, true);

    }

    handleDeployCrushedIceDialog = (action) => this.props.deployDialog(
        <DeliverySelectionDialog
            action={action}
            handleSubmit={this.handleCrushedIceAction}
            order={this.state.order}
        />, false, "Sales Order Management", "standard","xs", false, true);

    handleDeployFileUpload = () => this.props.deployDialog(
        <DocumentForm 
            id={this.state.orderId} 
            type="order"
            category="Sales Orders"
            callback={this.loadComponentData} 
            cancel={this.props.closeDialog} 
        />, false, "File Upload", "standard","xs", false, true);

    handleDeployPurchaseOrder = id => this.props.deployDialog(
        <ViewPurchaseOrder 
            id={id} 
        />, true, "Purchase Order", "standard","xl", true);
    
    handleDeploySendEmail = () => {
        let defaultFormData = {
            emailSubject: `Order Confirmation - #${this.state.order.order_reference}${!_.isEmpty(this.state.order.order_customer_reference) ? ` (${this.state.order.order_customer_reference})` : ''}`,
            emailTo: {
                label: this.state.order.order_email,
                value: this.state.order.order_email
            }
        }
        this.props.deployDialog(
            <EmailDialog
                callback={this.loadComponentData}
                id={this.state.orderId}
                type='orderConfirmation'
                success='The order confirmation has been successfully sent'
                defaultFormData={defaultFormData}
                readOnly
            />, false, "E-mail Order Confirmation", "standard","md"
        );
    }

    handleDeployViewCustomer = () => this.props.history.push(`/customers/${this.state.order?.customer?.cust_id}`);
    
    getProcessName = () => {
        const { order } = this.state;
        let text = null;
        switch(order.order_status) {
            case "Awaiting Approval":
                text = 'Approve / Decline Order'
            break;
            case "Awaiting Payment":
                text = 'Process Payment'
            break;
            case "Awaiting Authorisation":
                text = 'Process Authorisation'
            break;
            case "Processing":
                text = 'Process Sales Order'
            break;
            default:
                text = 'Process'
            break;
        }
        return text;
    }

    hasAccessToProcess = () => {
        const { access, order } = this.state;
        switch(order.order_status) {
            case "Awaiting Approval":
                return access.processApproval
            case "Awaiting Payment":
                return access.processPayment
            case "Awaiting Authorisation":
                return access.processAuthorisation
            case "Processing":
                return access.processProcessing
            default:
                return false;
        }
    }

    handlePdfLoad = ({numPages}) => {
        this.setState({
            pdf: {
                ...this.state.pdf,
                numPages
            }
        })
    }

    handleLockAllDeliveries = () => {

        const {
            deployConfirmation
        } = this.props;

        const { 
            order
        } = this.state;

        const lockable = _.filter(order.deliveries, el => el.od_status !== "Invoiced" && el.od_status !== "Cancelled" && el.od_on_hold_lock === 0);
        const hasPo = _.some(lockable, el => el.od_type === 'PO' && el.pod?.pod_ship_aa_only === 0);
        const deliveryIds = _.map(lockable, el => el.od_id);

        if(hasPo) {
            deployConfirmation(`Are you sure you want to lock all deliveries?`, () => this.handleConfirmLockOrder(deliveryIds))
        } else {
            deployConfirmation(`Are you sure you want to lock all deliveries?`, () => this.handleLockOrder(deliveryIds))
        }
    }

    handleConfirmLockOrder = (deliveryIds) => {

        const {
            deployConfirmation
        } = this.props;

        deployConfirmation(`Do you want to hold supplier direct deliveries with the supplier?`, () => this.handleLockOrder(deliveryIds, 1), () => this.handleLockOrder(deliveryIds))

    }

    handleLockOrder = (deliveryIds, ddStop = 0) => {

        const {
            deploySnackBar
        } = this.props;

        const {
            order
        } = this.state;

        API.put(`/sales/${order.order_id}/lock`, { deliveryIds, ddStop })
        .then((result) => {
            this.loadComponentData();
            if (result?.data?.success) {
                deploySnackBar("success", "You have successfully locked this order");
            } else {
                deploySnackBar("error", "Something went wrong, please try again");
            }
        });

    }

    handleUnlockOrder = () => {

        const {
            deploySnackBar
        } = this.props;

        const {
            order
        } = this.state;

        API.put(`/sales/${order.order_id}/lock/release`)
        .then((result) => {
            this.loadComponentData();
            if (result?.data?.success) {
                deploySnackBar("success", "You have successfully released this order");
            } else {
                deploySnackBar("error", "Something went wrong, please try again");
            }
        });

    }

    handlePdfPagination = offset => {
        this.setState({
            pdf: {
                ...this.state.pdf,
                pageNumber: ((this.state.pdf?.pageNumber ?? 1) + offset)
            }
        })
    }

    handlePdfScale = zoom => {
        let scale = this.state.pdf?.scale ?? 1;
        if(isNumeric(zoom)) {
            scale = zoom;
        } else {
            if(zoom) {
                scale = (parseFloat(scale) + parseFloat(0.25)).toFixed(2)
            } else {
                scale = (parseFloat(scale) - parseFloat(0.25)).toFixed(2)
            }
        }
        this.setState({
            pdf: {
                ...this.state.pdf,
                scale
            }
        })
    }

    render = () => {        
        const { classes, closeDialog, deployConfirmation, deployDialog, deploySnackBar, staff, ui } = this.props;
        const { access, currentTab, fulfilmentPercent, isLoading, key, order, pdf, revision, userIsCrushedIce, viewing } = this.state;
        const canDial = this.context?.dial === null;
        const height =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${ui.device.height}px)` : ui.device.height;
        const contentHeight =  window.matchMedia('(display-mode: standalone)').matches ? `calc(env(safe-area-inset-bottom) + ${(ui.device.height - VIEW_PAGE_OFFSET)}px)` : (height - VIEW_PAGE_OFFSET);
        const tabs = [
            {
                label: `Overview`,
                delivery: null,
                index: 0
            },
            {
                label: `Order Summary`,
                delivery: null,
                index: 1
            },
            {
                label: `PDF Document`,
                delivery: null,
                index: 2,
                disabled: order.order_legacy === 1,
            },
            {
                divider: true
            }
        ];

        if(!_.isEmpty(order.deliveries)) {
            tabs.push(
                {
                    label: `All Deliveries`,
                    delivery: null,
                    index: 8,
                    disabled: revision || _.isEmpty(order.deliveries),
                }
            )
        }
        if(_.isEmpty(order.deliveries) || !_.isEmpty(_.filter(order.deliveries, el => el.od_type === 'WH'))) {
            tabs.push(
                {
                    label: `Allocation`,
                    delivery: null,
                    disabled: order.order_status !== 'Awaiting Approval' && order.order_status !== 'Awaiting Payment' && order.order_status !== 'Awaiting Authorisation' && order.order_status !== 'Processing' && (revision || order.order_ifm === 1 || order.order_status === "Despatched" || order.order_status === "Invoiced" || _.isEmpty(_.filter(order.deliveries, el => el.od_type === 'WH'))),
                    index: 9,
                }
            )
        }
        if(!_.isEmpty(order.deliveries)) {
            if(!_.isEmpty(_.concat(order.purchase_orders, _.filter(order.deliveries, el => el.purchase_order)))) {
                tabs.push(
                    {
                        label: `Purchase Orders`,
                        delivery: null,
                        index: 12,
                    },
                );
            }
            _.each(order.deliveries, (el, idx) => {
                tabs.push(
                    {
                        label: `Delivery #${el.od_reference}/${el.od_idx}`,
                        delivery: el.od_id,
                        index: (idx + 16),
                        disabled: revision,
                        idx
                    }
                )
            });
        }

        tabs.push(
            {
                divider: true
            }
        )

        tabs.push(
            {
                label: `Approvals`,
                delivery: null,
                index: 11,
                disabled: _.isEmpty(order.approvals)
            },
            {
                label: `Card Orders`,
                disabled: order.order_co_required === 0 || (order.order_co_required === 1 && order.order_co_sent === 0),
                index: 15
            },
            {
                label: <Badge color="primary" className="badge" badgeContent={order.all_files_require_action_count ?? order.files_require_action_count ?? 0} showZero={false}>Files</Badge>,
                delivery: null,
                disabled: _.isEmpty(order.documents),
                index: 5,
            },
            {
                label: `Payments`,
                delivery: null,
                index: 10,
                disabled: _.isEmpty(order.payments),
            },
            // {
            //     label: `Invoices`,
            //     delivery: null,
            //     index: 6,
            //     disabled: _.isEmpty(_.filter(order.deliveries, el => el.od_status === 'Invoiced'))
            // }
        );
        
        if(order.revisions?.[0]?.order_revision === order.order_revision) {
            tabs.push(
                {
                    divider: true
                },
                {
                    label: `Actions ${order.actions_count > 0 ? `(${order.actions_count})` : ``}`, 
                    delivery: null,
                    index: 14,
                },
                {
                    label: `Timeline ${_.size(order.activity) > 0 ? `(${_.size(order.activity)})` : ``}`, 
                    delivery: null,
                    index: 3,
                },
                {
                    label: `Calls ${_.size(order.call_logs) > 0 ? `(${_.size(order.call_logs)})` : ``}`,
                    delivery: null,
                    index: 4,
                },
                {
                    label: `Emails ${_.size(order.emails) > 0 ? `(${_.size(order.emails)})` : ``}`,
                    delivery: null,
                    index: 13,
                },
                {
                    label: `Notes ${_.size(order.notes) > 0 ? `(${_.size(order.notes)})` : ``}`,
                    delivery: null,
                    index: 7,
                }
            )
        }

        return (
            <>
                <Grid container style={{margin: -24, width: 'initial'}}>
                    {(isLoading && (
                        <Grid item xs={12}>
                            <LoadingCircle />
                        </Grid>
                    )) || (
                        <>
                            <Grid item xs={12}>
                                <AppBar style={{backgroundColor: '#fff', borderBottom: '1px solid #ddd', height: VIEW_PAGE_APP_BAR_HEIGHT}} position="static" elevation={0}>
                                    <Box pt={1} pr={1} pb={1} pl={3}>
                                        <Grid container alignItems='center'>
                                            <Grid item xs>
                                                <Grid container spacing={1} alignItems='center'>
                                                    <Grid item>
                                                        <Typography variant="h4" component={order.order_status === 'Cancelled' || revision ? 'strike' : undefined} className="textDefault">
                                                            Sales Order #{order.order_reference}{!_.isEmpty(order.order_customer_reference) ? ` (${order?.quote?.quote_customer_reference && order?.quote?.quote_customer_reference !== order.order_customer_reference ? `${order.quote.quote_customer_reference} > ` : ''}${order.order_customer_reference})` : ''}
                                                            {/* Sales Order #{order.order_reference}/{order.order_revision}{!_.isEmpty(order.order_customer_reference) ? ` (${order?.quote?.quote_customer_reference && order?.quote?.quote_customer_reference !== order.order_customer_reference ? `${order.quote.quote_customer_reference} > ` : ''}${order.order_customer_reference})` : ''} */}
                                                        </Typography>  
                                                    </Grid>
                                                    {order?.order_phone?.length > 0 && canDial ? (
                                                        <Grid item>
                                                            <Tooltip title={`Dial ${order.order_phone}`}>
                                                                <IconButton onClick={() => this.context.useDial(order.order_phone)} size="small">
                                                                    <FAIcon icon="circle-phone" type="solid" className="textSuccess" button noMargin size={27.5} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    ) : null}
                                                    {order.order_legacy === 2 && (
                                                        <>
                                                            <Grid item>
                                                                <FAIcon icon='tree-christmas' type='light' size={25} className='textDefault' />
                                                            </Grid>
                                                            <Grid item>
                                                                <FAIcon icon='candy-cane' type='light' size={25} className='textDefault' />
                                                            </Grid>
                                                            <Grid item>
                                                                <FAIcon icon='sleigh' type='light' size={35} className='textDefault' />
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>  
                                            </Grid>
                                            {!revision && (
                                                <Grid item>
                                                    <Tooltip title="Add Action" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddTask()}>
                                                            <FAIcon type="light" icon="layer-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.addNote && (
                                                <Grid item>
                                                    <Tooltip title="Add Note" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddNote()}>
                                                            <FAIcon type="light" icon="comment-alt-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.addCallLog && (
                                                <Grid item>
                                                    <Tooltip title="Log Call" placement="top">
                                                        <IconButton onClick={() => this.handleDeployAddCall()}>
                                                            <FAIcon type="light" icon="phone-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            {access.uploadDoc && (
                                                <Grid item>
                                                    <Tooltip title="Upload File" placement="top">
                                                        <IconButton onClick={() => this.handleDeployFileUpload()}>
                                                            <FAIcon type="light" icon="file-plus" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <Tooltip title="Download PDF" placement="top">
                                                    <IconButton onClick={() => order.order_legacy === 1 ? getLegacyOrderPDF(order.order_id) : getPresignedUrl(order.order_pdf_filename)}>
                                                        <FAIcon type="light" icon="file-pdf" style={{color:'#d32f2f'}} button noMargin size={17.5} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            {(order.order_status === 'Awaiting Approval' || order.order_status === 'Awaiting Payment' || order.order_status === 'Awaiting Authorisation' || order.order_status === 'Processing') && access.processOrder && (
                                                <Grid item>
                                                    <Tooltip title={this.getProcessName()} disabled={!this.hasAccessToProcess()} placement="top">
                                                        <IconButton onClick={() => this.props.history.push(`/sales/process/${order.order_id}`)}>
                                                            <FAIcon type="light" disabled={!this.hasAccessToProcess()} icon="check" button noMargin size={17.5} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <ActionMenu
                                                    rows={[
                                                        {
                                                            icon: "layer-plus",
                                                            label: "Add Action",
                                                            onClick: () => this.handleDeployAddTask(),
                                                            display: !revision
                                                        },
                                                        {
                                                            icon: "comment-alt-plus",
                                                            label: "Add Note",
                                                            onClick: () => this.handleDeployAddNote(),
                                                            display: access.addNote
                                                        },
                                                        {
                                                            icon: "phone-plus",
                                                            label: "Log Call",
                                                            onClick: () => this.handleDeployAddCall(),
                                                            display: access.addCallLog
                                                        },
                                                        {
                                                            icon: "file-plus",
                                                            label: "Upload File",
                                                            onClick: () => this.handleDeployFileUpload(),
                                                            display: access.uploadDoc,
                                                            divider: true
                                                        },
                                                        {
                                                            icon: "file-pdf",
                                                            label: "Download PDF",
                                                            onClick: () => order.order_legacy === 1 ? getLegacyOrderPDF(order.order_id) : getPresignedUrl(order.order_pdf_filename),
                                                            display: true,
                                                        },
                                                        {
                                                            icon: "envelope-open-text",
                                                            label: "E-mail Order Confirmation",
                                                            onClick: () => this.handleDeploySendEmail(),
                                                            display: true,
                                                            disabled: _.isEmpty(order.deliveries) || order.order_status === "Cancelled" || !access.emailConfirmation
                                                        },
                                                        {
                                                            icon: "pound-sign",
                                                            label: "Print Card Payment Form",
                                                            onClick: () => this.handleDeployCardDetailsForm(),
                                                            display: order.order_legacy !== 2,
                                                            divider: true,
                                                        },
                                                        {
                                                            icon: "check",
                                                            label: this.getProcessName(),
                                                            onClick: () => this.props.history.push(`/sales/process/${order.order_id}`),
                                                            display: (order.order_status === 'Awaiting Approval' || order.order_status === 'Awaiting Payment' ||  order.order_status === 'Awaiting Authorisation' || order.order_status === 'Processing') && access.processOrder,
                                                            disabled: order.order_legacy === 2 || viewing.update || viewing.process || viewing.pick || viewing.check || viewing.build || viewing.despatch || !this.hasAccessToProcess()
                                                        },
                                                        {
                                                            icon: "undo",
                                                            label: "Cancel Despatch",
                                                            onClick: () => this.handleDeployCrushedIceDialog('cancelDespatch'),
                                                            display: true,
                                                            disabled: !access.cancelDespatch || order.order_status === "Cancelled" || order.order_status === "Invoiced" || order.order_ifm || _.isEmpty(order.deliveries) || _.every(order.deliveries, el => el.od_status !== "Despatched")
                                                        },
                                                        {
                                                            icon: "box",
                                                            label: "Change Fulfilment",
                                                            onClick: () => this.props.history.push(`/sales/process/${order.order_id}`),
                                                            display: access.processOrder,
                                                            disabled: order.order_legacy === 2 || _.isEmpty(order.deliveries) || order.order_status === 'Cancelled' || order.order_status === 'Invoiced' || order.order_status === 'Despatched' || order.order_status === 'Awaiting Approval' || order.order_status === 'Awaiting Payment' || order.order_status === 'Processing' || viewing.update || viewing.process || viewing.pick || viewing.check || viewing.build || viewing.despatch
                                                        },
                                                        {
                                                            icon: "pencil",
                                                            label: "Modify Order",
                                                            onClick: () => this.props.history.push(`/sales/update/${order.order_id}`),
                                                            display: access.updateOrder,
                                                            disabled: order.order_legacy === 2 || order.order_status === 'Cancelled' || order.order_status === 'Invoiced' || order.order_status === 'Despatched' || viewing.update || viewing.process || viewing.pick || viewing.check || viewing.build || viewing.despatch
                                                        },
                                                        {
                                                            icon: "times",
                                                            label: "Cancel Order",
                                                            onClick: this.handleDeployCancelOrder,
                                                            display: access.cancelOrder,
                                                            disabled: order.order_legacy === 2 || (order?.deliveries?.length && order.order_status === 'Processing') || order.order_status === 'Cancelled' || order.order_status === 'Invoiced' || viewing.update || viewing.process || viewing.pick || viewing.check || viewing.build || viewing.despatch
                                                        },
                                                        {
                                                            icon: "exchange-alt",
                                                            label: "New RMA",
                                                            display: access.newRMA,
                                                            disabled: _.every(order.deliveries, el => el.od_status !== 'Invoiced') || order.order_legacy === 2,
                                                            divider: true,
                                                            onClick: () => this.props.history.push(`/rma/new/sales-order/${order.order_id}`)
                                                        },
                                                        {
                                                            icon: 'lock',
                                                            label: 'Lock Order',
                                                            onClick: this.handleLockAllDeliveries,
                                                            display: access.lockDelivery,
                                                            disabled: _.isEmpty(_.filter(order.deliveries, el => el.od_status !== "Invoiced" && el.od_status !== "Cancelled" && el.od_on_hold_lock === 0))  
                                                        },
                                                        {
                                                            icon: 'lock-open',
                                                            label: 'Release Order',
                                                            onClick: () => deployConfirmation(`Are you sure you want to release all deliveries?`, this.handleUnlockOrder),
                                                            display: access.releaseDelivery || access.releaseDeliveryAccounts,
                                                            disabled: _.isEmpty(_.filter(order.deliveries, el => el.od_on_hold_lock === 1)) || (_.some(_.filter(order.deliveries, el => el.od_on_hold_lock === 1), _el => _el?.lock?.ol_reason !== 'Manual') && !access.releaseDeliveryAccounts),
                                                            divider: true
                                                        },
                                                        {
                                                            icon: "redo",
                                                            label: "Re-order",
                                                            onClick: () => this.props.history.push(`/sales/replicate/${order.order_id}`),
                                                            display: access.reOrder,
                                                            disabled: order.order_legacy === 2
                                                        },
                                                        {
                                                            icon: "binoculars",
                                                            label: "View Customer",
                                                            onClick: () => this.handleDeployViewCustomer(),
                                                            display: access.viewCustomer,
                                                            divider: true
                                                        },
                                                        {
                                                            icon: "hat-wizard",
                                                            label: "CI: Manual Despatch",
                                                            onClick: () => this.handleDeployCrushedIceDialog('manualDespatch'),
                                                            display: userIsCrushedIce,
                                                            disabled: order.order_status === "Cancelled" || order.order_status === "Despatched" || order.order_status === "Invoiced" || order.order_ifm || _.isEmpty(order.deliveries) // this next bit needs tweaking before AA usage - || _.isEmpty(_.filter(order.deliveries, el => (!el.pod || (el.pod && el.pod?.pod_delivered === 0 && el.pod?.pod_ship_aa_only === 1)) && (el.od_status === 'Awaiting Allocation' || el.od_status === 'Awaiting Stock' || el.od_status === 'Awaiting Picking' || el.od_status === 'Awaiting Check' || el.od_status === 'Awaiting Despatch' || el.od_status === 'Ready for Collection'))) || order.order_ifm
                                                        },
                                                        {
                                                            icon: "hat-wizard",
                                                            label: "CI: Re-generate Invoice PDF",
                                                            onClick: () => this.handleDeployCrushedIceDialog('regenerateInvoicePdf'),
                                                            display: userIsCrushedIce,
                                                            disabled: _.isEmpty(order.deliveries) || _.every(order.deliveries, el => el.od_status !== 'Invoiced')
                                                        }
                                                    ]}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container justify="space-between" alignItems='center'>
                                                <Grid item xs={10} md={8}>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <InsightChip 
                                                                disabled={!access.viewCustomer}
                                                                icon="external-link"
                                                                label={`Customer: ${order.customer?.cust_company_name}`}
                                                                onClick={() => this.handleDeployViewCustomer()}
                                                                paddingLeft={8}
                                                                tooltip="View Customer"
                                                            />
                                                        </Grid>
                                                        {!ui.device?.isTablet && !ui.device?.isMobile && (
                                                            <Grid item>
                                                                <InsightChip 
                                                                    icon="user"
                                                                    label={`Contact: ${order.order_contact_name}${!_.isEmpty(order.order_phone) ? ` - ${order.order_phone}` : ``}`}
                                                                />
                                                            </Grid>
                                                        )}
                                                        <Grid item>
                                                            <InsightChip 
                                                                icon="info-circle"
                                                                label={`Status: ${revision ? 'Revised' : order.order_status}${order.order_status !== 'Cancelled' && _.some(order?.deliveries, el => el.od_status === 'Cancelled') ? ` (Partially Cancelled)` : ``}`}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <InsightChip 
                                                                icon="calendar-day"
                                                                label={`Ordered: ${moment(order.order_created_datetime).format("DD/MM/YYYY")}`}
                                                            />
                                                        </Grid>
                                                        {/* <Grid item>
                                                            <InsightChip 
                                                                icon="pound-sign"
                                                                label={`Net: ${currencyFormat.format(order.order_total_net)}`}
                                                            />
                                                        </Grid> */}
                                                        {!ui.device?.isPortrait && order.order_legacy !== 2 && (
                                                            <Grid item>
                                                                <InsightChip 
                                                                    icon="pound-sign"
                                                                    label={`Gross Total: ${currencyFormat.format(order.order_total_gross)}`}
                                                                />
                                                            </Grid>
                                                        )}
                                                        {order.order_legacy === 2 && (
                                                            <>
                                                                <Grid item>
                                                                    <InsightChip 
                                                                        icon="gift"
                                                                        label={`Customer Gift`}
                                                                    />
                                                                </Grid>
                                                            </>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Hidden smDown>
                                                    <Grid item xs={4}>
                                                        <Grid container spacing={2} justify="flex-end">
                                                            <Grid item>
                                                                <ActionChip action={{u: 0, p: 0}} staff={staff[this.props.loggedInStaff.id]} page="sales order" />
                                                            </Grid>
                                                            {_.map(viewing.list, (view, idx) => {
                                                                if(staff[view.i]) {
                                                                    return (
                                                                        <Grid item key={idx}>
                                                                            <ActionChip action={view} staff={staff[view.i]} page="sales order" />
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })}
                                                        </Grid>
                                                    </Grid>
                                                </Hidden>
                                                <Hidden mdUp>
                                                    <Grid item xs={2} align='right'>
                                                        <ActionChip staff={{name: `${(_.size(viewing.list) + 1)} viewing`}} page="sales order" />
                                                    </Grid>
                                                </Hidden>
                                            </Grid> 
                                        </Grid>
                                    </Box>
                                </AppBar>
                            </Grid> 
                            <Grid item ref={this.mainContentArea} className={classes.main} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                <Grid container>
                                    {(viewing.pick && (
                                        <Grid item xs={12}>
                                            <Box borderBottom='1px solid #ddd'>
                                                <Alert severity="warning" variant="standard" elevation={0}>
                                                    <strong>Pick In Progress - </strong>{staff[_.find(viewing.list, el => el.pi === 1)?.i]?.name} is currently picking a delivery for this sales order
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )) || (viewing.check && (
                                        <Grid item xs={12}>
                                            <Box borderBottom='1px solid #ddd'>
                                                <Alert severity="warning" variant="standard" elevation={0}>
                                                    <strong>Check In Progress - </strong>{staff[_.find(viewing.list, el => el.ch === 1)?.i]?.name} is currently checking a delivery for this sales order
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )) || (viewing.despatch && (
                                        <Grid item xs={12}>
                                            <Box borderBottom='1px solid #ddd'>
                                                <Alert severity="warning" variant="standard" elevation={0}>
                                                    <strong>Despatch In Progress - </strong>{staff[_.find(viewing.list, el => el.de === 1)?.i]?.name} is currently despatching a delivery for this sales order
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )) || (viewing.process && (
                                        <Grid item xs={12}>
                                            <Box borderBottom='1px solid #ddd'>
                                                <Alert severity="warning" variant="standard" elevation={0}>
                                                    <strong>Processing Now - </strong>{staff[_.find(viewing.list, el => el.p === 1)?.i]?.name} is currently processing this sales order
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )) || (order.order_ifm === 1 && (
                                        <Grid item xs={12}>
                                            <Box borderBottom='1px solid #ddd'>
                                                <Alert severity="warning" variant="standard" elevation={0}>
                                                    <strong>Urgent - </strong>This order is live and has been modified, please {order.order_ifm_approval === 1 ? 'approve/decline' : (order.order_ifm_payment === 1 ? 'take payment' : 're-process')} it as soon as possible!
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )) || (_.some(order.deliveries, el => el.od_on_hold === 1 && el.od_on_hold_lock === 1 && el.od_on_hold_datetime !== "0000-00-00 00:00:00") && (
                                        <Grid item xs={12}>
                                            <Box borderBottom='1px solid #ddd'>
                                                <Alert severity="error" variant="standard" elevation={0}>
                                                    <strong>Important - </strong>This order has deliveries which are pending release
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )) || (_.some(order.deliveries, el => el.od_on_hold === 1 && el.od_on_hold_lock === 0 && el.od_on_hold_datetime !== "0000-00-00 00:00:00") && (
                                        <Grid item xs={12}>
                                            <Box borderBottom='1px solid #ddd'>
                                                <Alert severity="warning" variant="standard" elevation={0}>
                                                    <strong>Urgent - </strong>There is an availability issue with this order, please review the deliveries for more information
                                                </Alert>
                                            </Box>
                                        </Grid>
                                    )) || (
                                        <>
                                        {(order.reason && (
                                            <Grid item xs={12}>
                                                <Box borderBottom='1px solid #ddd'>
                                                    <Alert severity="warning" variant="standard" elevation={0}>
                                                        Sales Order{order.order_status !== 'Cancelled' ? ` Partially` : ``} Cancelled - {order?.reason?.or_reason}
                                                    </Alert>
                                                </Box>
                                            </Grid>
                                        )) || (order.order_status === 'Cancelled' && (
                                            <Grid item xs={12}>
                                                <Box borderBottom='1px solid #ddd'>
                                                    <Alert severity="warning" variant="standard" elevation={0}>
                                                        Sales Order{order.order_status !== 'Cancelled' ? ` Partially` : ``} Cancelled - No reason given
                                                    </Alert>
                                                </Box>
                                            </Grid>
                                        )) || (order?.basket?.discount_use?.discount?.td_name?.length > 0 && (
                                            <Grid item xs={12}>
                                                <Box borderBottom='1px solid #ddd'>
                                                    <Alert severity="success" variant="standard" elevation={0}>
                                                        <b>
                                                            Portal discount code used - {order?.basket?.discount_use?.discount?.td_name} ({order?.basket?.discount_use?.discount?.td_code})
                                                        </b>
                                                    </Alert>
                                                </Box>
                                            </Grid>
                                        ))}
                                        </>
                                    )}
                                    <Grid item xs={12}>  
                                        <Box p={3}>
                                            <TabPanel value={currentTab} index={0}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} lg={8}>
                                                        <Grid container spacing={3}> 
                                                            <Grid item xs={12} md={4}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                        <Heading text="Financials" />
                                                                            <Grid container spacing={2}>   
                                                                                <Grid item xs={12}>                                                        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="pound-sign"
                                                                                        title="Subtotal"
                                                                                        content={`${currencyFormat.format(order.order_total_sub)}`}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>                                                        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="minus"
                                                                                        title="Discount"
                                                                                        content={`${currencyFormat.format(order.order_total_discount)}`}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>                                                        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="plus"
                                                                                        title="Delivery"
                                                                                        content={`${currencyFormat.format(order.order_total_carriage)}`}
                                                                                    />
                                                                                </Grid> 
                                                                                <Grid item xs={12}>
                                                                                    <Divider />
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <Box pt={0.4} pb={0.4}>
                                                                                        <Tile
                                                                                            noPaper
                                                                                            icon="pound-sign"
                                                                                            title="Net Total"
                                                                                            content={`${currencyFormat.format(order.order_total_net)}`}
                                                                                        />
                                                                                    </Box>
                                                                                </Grid> 
                                                                                <Grid item xs={12}>                                                        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="plus"
                                                                                        title="VAT"
                                                                                        content={`${currencyFormat.format(order.order_total_vat)}`}
                                                                                    />
                                                                                </Grid> 
                                                                                <Grid item xs={12}>                                                        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="pound-sign"
                                                                                        title="Gross Total"
                                                                                        content={`${currencyFormat.format(order.order_total_gross)}`}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <Divider />
                                                                                </Grid>
                                                                                <Grid item xs={12}>                                                           
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="percent"
                                                                                        title="Margin"
                                                                                        content={`${parseFloat(order.order_total_margin).toFixed(2)}%`}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>                                                           
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="info-circle"
                                                                                        title="Blanket Order Discount"
                                                                                        content={`${order.order_blanket_discount > 0 ? `${parseFloat(order.order_blanket_discount).toFixed(2)}%` : `Not Used`}`}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>                                                        
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="info-circle"
                                                                                        title="Payment Terms"
                                                                                        content={order?.payment_terms?.pt_name ?? 'Unknown'}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} md={8}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                            <Heading text="Order Status" />
                                                                            <Grid container spacing={3}>   
                                                                                <Grid item xs={12} lg={6}> 
                                                                                    <BooleanTile
                                                                                        isTrue={order.order_status === 'Despatched' || order.order_status === 'Invoiced'}
                                                                                        noPaper
                                                                                        title={`Order Status`}
                                                                                        content={order.order_status}
                                                                                    />     
                                                                                </Grid> 
                                                                                <Grid item xs={12} lg={6}> 
                                                                                    <BooleanTile
                                                                                        isTrue={order.order_allocated}
                                                                                        noPaper
                                                                                        title={`Order Allocated`}
                                                                                        content={order.order_allocated ? 'Yes' : 'No'}
                                                                                    />     
                                                                                </Grid> 
                                                                                <Grid item xs={12} lg={6}>   
                                                                                    <BooleanTile
                                                                                        isTrue={order.revisions?.[0].order_revision === order.order_revision}
                                                                                        noPaper
                                                                                        title={`Latest Revision`}
                                                                                        content={order.revisions?.[0].order_revision}
                                                                                    />      
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>   
                                                                                    <BooleanTile
                                                                                        isTrue={order.order_requested_delivery_date === "0000-00-00"}
                                                                                        noPaper
                                                                                        title={`Customer Requested Date`}
                                                                                        content={order.order_requested_delivery_date === "0000-00-00" ? 'Not Given' : moment(order.order_requested_delivery_date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                                    />           
                                                                                </Grid>
                                                                            </Grid>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                            <Heading text="Order Processing" />
                                                                            <Grid container spacing={3}>    
                                                                                <Grid item xs={12} lg={6}>  
                                                                                    <BooleanTile
                                                                                        isTrue={order.order_placed_datetime !== '0000-00-00 00:00:00'}
                                                                                        noPaper
                                                                                        title={`Order Placed`}
                                                                                        content={order.order_placed_datetime === '0000-00-00 00:00:00' ? 'N/A' : moment(order.order_placed_datetime, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                                    />      
                                                                                </Grid> 
                                                                                <Grid item xs={12} lg={6}>  
                                                                                    <BooleanTile
                                                                                        isTrue={order.order_approved_datetime !== '0000-00-00 00:00:00'}
                                                                                        noPaper
                                                                                        title={`Order Approved`}
                                                                                        content={order.order_approved_datetime === '0000-00-00 00:00:00' ? 'N/A' : moment(order.order_approved_datetime, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                                    />     
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}> 
                                                                                    <BooleanTile
                                                                                        isTrue={order.order_paid_datetime !== '0000-00-00 00:00:00'}
                                                                                        noPaper
                                                                                        title={`Order Paid`}
                                                                                        content={order.order_paid_datetime === '0000-00-00 00:00:00' ? 'N/A' : moment(order.order_paid_datetime, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                                    />  
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>  
                                                                                    <BooleanTile
                                                                                        isTrue={order.order_processed_datetime !== '0000-00-00 00:00:00'}
                                                                                        noPaper
                                                                                        title={`Order Processed`}
                                                                                        content={order.order_processed_datetime === '0000-00-00 00:00:00' ? 'N/A' : moment(order.order_processed_datetime, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>    
                                                                                    <BooleanTile
                                                                                        isTrue={order.order_despatched_datetime !== '0000-00-00 00:00:00'}
                                                                                        noPaper
                                                                                        title={`Order Despatched`}
                                                                                        content={order.order_despatched_datetime === '0000-00-00 00:00:00' ? 'N/A' : moment(order.order_despatched_datetime, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} lg={6}>  
                                                                                    <BooleanTile
                                                                                        isTrue={order.order_invoiced_datetime !== '0000-00-00 00:00:00'}
                                                                                        noPaper
                                                                                        title={`Order Invoiced`}
                                                                                        content={order.order_invoiced_datetime === '0000-00-00 00:00:00' ? 'N/A' : moment(order.order_invoiced_datetime, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <PaddedPaper>
                                                                            <Heading text="Insight" />
                                                                            <Grid container spacing={3}>
                                                                                <Grid item xs={12} lg={6}>
                                                                                    <Tile
                                                                                        noPaper
                                                                                        icon="chart-network"
                                                                                        title="Last Activity"
                                                                                        content={order.activity?.[0]?.d ? moment(order.activity?.[0]?.d).format("DD/MM/YY HH:mm") : '-'}
                                                                                        onClick={() => this.handleTabChange(3)}
                                                                                    />
                                                                                </Grid> 
                                                                                <Grid item xs={12} lg={6}>                                                        
                                                                                    <StaffTile
                                                                                        noPaper
                                                                                        title="Ordered By"
                                                                                        staffId={order.order_staff_id}
                                                                                        missingText={order.order_staff_id === -1 ? "E-commerce" : null}
                                                                                    />
                                                                                </Grid> 
                                                                            </Grid>    
                                                                        </PaddedPaper>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} lg={4}>
                                                        <Grid container spacing={3}>
                                                            {order.rma && (
                                                                <Grid item xs={12}>
                                                                    <PaddedPaper>
                                                                        <Grid container spacing={1} alignItems='center'>
                                                                            <Grid item xs>
                                                                                <Typography variant="h6">
                                                                                    Replacement Order
                                                                                </Typography>    
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Typography variant="body2" gutterBottom>
                                                                                    This sales order was generated as a replacement order for the following RMA:
                                                                                </Typography>                                                                                    
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Tooltip title="View RMA">
                                                                                    <RouterLink to={`/rma/${order?.rma?.rma_id}`} target="_blank">
                                                                                        <InsightChip
                                                                                            icon='external-link'
                                                                                            className="link"
                                                                                            label={`RMA #${order?.rma?.rma_reference} (${order?.rma?.rma_status})`}
                                                                                            paddingRight={4}
                                                                                            paddingLeft={8}
                                                                                        />
                                                                                    </RouterLink>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </PaddedPaper>
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Heading text="Order Contact" />
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12} lg={6}>
                                                                            <Typography variant="caption" color="textSecondary" gutterBottom>
                                                                                {/* <FAIcon  style={{color: 'rgba(0, 0, 0, 0.54)'}} icon="user" size="small" /> */}
                                                                                Contact Name
                                                                            </Typography> 
                                                                            <Typography variant="body2">
                                                                                {order.order_contact_name}
                                                                            </Typography>    
                                                                        </Grid>
                                                                        <Grid item xs={12} lg={6}>
                                                                            {!_.isEmpty(order.order_phone) && (
                                                                                <>
                                                                                    <Typography variant="caption" color="textSecondary" gutterBottom>
                                                                                        {/* <FAIcon  style={{color: 'rgba(0, 0, 0, 0.54)'}} icon="phone" size="small" /> */}
                                                                                        Phone Number
                                                                                    </Typography> 
                                                                                    <Typography variant="body2">
                                                                                        {order.order_phone}
                                                                                    </Typography>    
                                                                                </>
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                </PaddedPaper>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <PaddedPaper>
                                                                    <Grid container spacing={1} alignItems='center'>
                                                                        <Grid item xs>
                                                                            <Typography variant="h6">
                                                                                Deliveries
                                                                            </Typography>    
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Button
                                                                                size="small"
                                                                                variant="text"
                                                                                onClick={() => this.handleTabChange(8)}
                                                                            >
                                                                                <FAIcon icon="eye" size={15} button />
                                                                                Detailed View
                                                                            </Button>
                                                                        </Grid>
                                                                        <Grid item xs={12} className="pt-0">
                                                                            <Box display="flex" alignItems="center" pt={1}>
                                                                                <Box width="100%" mr={1}>
                                                                                    <LinearProgress variant="determinate" value={fulfilmentPercent} />
                                                                                </Box>
                                                                                <Box minWidth={35}>
                                                                                    <Typography variant="body2" color="textSecondary" className="fw-400">{fulfilmentPercent}%</Typography>
                                                                                </Box>
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            {(!_.isEmpty(order.deliveries) && (
                                                                                <DataTable  
                                                                                    config={{
                                                                                        key: 'od_id',
                                                                                        alternatingRowColours: true,
                                                                                        isLoading: isLoading,
                                                                                        // responsiveImportance: true,
                                                                                        inline: true,
                                                                                        noRowHover: true
                                                                                    }}
                                                                                    columns={[
                                                                                        {
                                                                                            heading: '',
                                                                                            field: rowData => {
                                                                                                let despatchDate = rowData.od_schedule_date;
                                                                                                let despatchText = 'Despatching';
                                                                                                let toHere = false;
                                                                                                let podDate = null;
                                                                                                const hasConsolidation = rowData?.consolidation?.length > 0 ?? false;
                                                                                                const hasParent = rowData?.parent?.length > 0 ?? false;
                                                                                                if(rowData.pod && (!rowData.pod.pod_ship_aa_only || (rowData.pod.pod_ship_aa_only && !rowData?.pod?.pod_delivered))) {
                                                                                                    if(rowData.pod.pod_ship_aa_only) {
                                                                                                        toHere = true;
                                                                                                    }
                                                                                                    if(rowData.pod.pod_despatch_date !== '0000-00-00') {
                                                                                                        despatchDate = rowData.pod.pod_despatch_date;
                                                                                                        despatchText = 'Despatched';
                                                                                                    } else if(rowData.pod.pod_check_date !== '0000-00-00') {
                                                                                                        despatchDate = rowData.pod.pod_check_date;
                                                                                                        podDate = rowData.pod.pod_check_date;
                                                                                                    } else {
                                                                                                        if(!rowData.od_confirmed) {
                                                                                                            despatchText = 'Requested despatch'
                                                                                                            despatchDate = rowData.pod.pod_req_despatch_date;
                                                                                                        } else {
                                                                                                            despatchDate = rowData.pod.pod_expected_despatch_date;
                                                                                                        }
                                                                                                        podDate = rowData.pod.pod_expected_despatch_date;
                                                                                                    }
                                                                                                } else {
                                                                                                    if(order.order_requested_delivery_date !== '0000-00-00') {
                                                                                                        if(moment(despatchDate, "YYYY-MM-DD").isBefore(moment(order.order_requested_delivery_date, "YYYY-MM-DD"))) {
                                                                                                            despatchDate = order.order_requested_delivery_date;
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                                if(rowData.despatch && (rowData.od_status === 'Despatched' || rowData.od_status === 'Invoiced')) {
                                                                                                    despatchDate = rowData.despatch.desp_shipping_date;
                                                                                                    despatchText = 'Despatched';
                                                                                                }
                                                                                                let reqDate = null;
                                                                                                if(rowData.od_status !== "Invoiced" && rowData.od_status !== "Despatched" && order.order_requested_delivery_date !== '0000-00-00') {
                                                                                                    reqDate = rowData.od_schedule_date;
                                                                                                    if(moment(reqDate, "YYYY-MM-DD").isBefore(moment(order.order_requested_delivery_date, "YYYY-MM-DD"))) {
                                                                                                        reqDate = order.order_requested_delivery_date;
                                                                                                    }
                                                                                                }
                                                                                                return (
                                                                                                    <Box pt={1} pb={1}>
                                                                                                        <Typography variant="body2" className="fw-400">
                                                                                                            {`Delivery #${rowData.od_reference}/${rowData.od_idx}`}
                                                                                                        </Typography>
                                                                                                        {!hasConsolidation ? (
                                                                                                            <Typography variant="caption" component="div">
                                                                                                                {`${despatchText}${toHere ? ` to here` : ``} on ${moment(despatchDate, "YYYY-MM-DD").format("DD/MM/YYYY")}`}
                                                                                                            </Typography>
                                                                                                        ) : null}
                                                                                                        <Typography variant="caption" component="div">
                                                                                                            {`${rowData.purchase_order?.supplier?.supp_company_name ?? 'Advanced Access'}`}
                                                                                                        </Typography>
                                                                                                        {rowData?.pod && rowData?.pod?.pod_check_date !== '0000-00-00' ? (
                                                                                                            <Typography variant="caption" component="div" className="textError">
                                                                                                                Check Date: {moment(rowData.pod.pod_check_date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                                                            </Typography>
                                                                                                        ) : null}
                                                                                                        {reqDate ? (
                                                                                                            <Typography variant="caption" component="div" className="textError">
                                                                                                                Customer Requested Date: {moment(reqDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
                                                                                                            </Typography>
                                                                                                        ) : null}
                                                                                                        {hasConsolidation ? (
                                                                                                            <Typography variant="caption" component="div" className="textError">
                                                                                                                Ship Complete: Despatch Blocked
                                                                                                            </Typography>
                                                                                                        ) : null}
                                                                                                        {hasParent ? (
                                                                                                            <Typography variant="caption" component="div" className="textError">
                                                                                                                Ship Complete: Pending Consolidation
                                                                                                            </Typography>
                                                                                                        ) : null}
                                                                                                        {reqDate && podDate && !rowData?.pod?.pod_delivered && moment(podDate, "YYYY-MM-DD").isBefore(moment(reqDate, "YYYY-MM-DD")) ? (
                                                                                                            <Typography variant="caption" component="div" className="fw-400 textError">
                                                                                                                ** PO DATE BEFORE DELIVERY REQUESTED DATE **
                                                                                                            </Typography>
                                                                                                        ) : null}
                                                                                                    </Box>
                                                                                                )
                                                                                            },
                                                                                            truncate: true
                                                                                        }, 
                                                                                        {
                                                                                            heading: '',
                                                                                            field: rowData => rowData.od_sys_hold === 1 ? <><div>{rowData.od_status}</div><Typography variant="caption" className="textError">On Hold</Typography></> : <>{rowData.od_status}{!_.isEmpty(rowData?.invoice?.inv_reference) ? <Typography component="div" variant="caption">Invoice: #{rowData.invoice.inv_reference}</Typography> : ``}</>,
                                                                                            sizeToContent: true
                                                                                        },      
                                                                                        // {
                                                                                        //     actions: rowData => {
                                                                                        //         return [
                                                                                        //             {name: 'Delivery Information', icon: 'binoculars', onClick: () => this.handleTabChange((rowData.rowIdx + 16)), doubleClick: true},
                                                                                        //         ]
                                                                                        //     }
                                                                                        // }
                                                                                    ]}
                                                                                    rows={order.deliveries}
                                                                                />
                                                                            )) || (
                                                                                <Box mt={1}>
                                                                                    <Alert severity={order.order_status === "Cancelled" ? 'error' : 'info'} variant="outlined">
                                                                                        {(order.order_status === "Cancelled" && (
                                                                                            `This sales order has been cancelled`
                                                                                        )) || (
                                                                                            `This sales order has not been processed yet`
                                                                                        )}
                                                                                    </Alert>
                                                                                </Box>
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                </PaddedPaper>
                                                            </Grid>
                                                            {!_.isEmpty(order.revisions) && (
                                                                <Grid item xs={12}>
                                                                    <PaddedPaper>
                                                                        <Grid container spacing={1} alignItems='center'>
                                                                            <Grid item xs>
                                                                                <Typography variant="h6">
                                                                                    Revisions
                                                                                </Typography>    
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <DataTable
                                                                                    config={{
                                                                                        key: 'order_id',
                                                                                        inline: true,
                                                                                    }}
                                                                                    columns={[
                                                                                        {
                                                                                            heading: 'Revision',
                                                                                            field: rowData => `${rowData.order_revision}`,
                                                                                            sizeToContent: true,
                                                                                            cellProps: rowData => ({
                                                                                                className: rowData.order_latest === 1 ? 'fw-400' : undefined
                                                                                            })
                                                                                        },
                                                                                        {
                                                                                            heading: 'Created By',
                                                                                            field: rowData => staff?.[rowData?.order_revision_staff_id ?? 0]?.name ?? (rowData.order_revision_staff_id === -1 ? 'E-commerce' : 'Unknown'),
                                                                                            sizeToContent: true,
                                                                                            cellProps: rowData => ({
                                                                                                className: rowData.order_latest === 1 ? 'fw-400' : undefined
                                                                                            })
                                                                                        },
                                                                                        {
                                                                                            heading: 'Created On',
                                                                                            field: rowData => moment(rowData.order_updated_datetime).format("DD/MM/YYYY"),
                                                                                            sizeToContent: true,
                                                                                            cellProps: rowData => ({
                                                                                                className: rowData.order_latest === 1 ? 'fw-400' : undefined
                                                                                            })
                                                                                        },
                                                                                        {
                                                                                            actions: rowData => {
                                                                                                return [
                                                                                                    {name: 'View', icon: 'binoculars', link: '/sales/' + rowData.order_id, disabled: rowData.order_id === order.order_id}
                                                                                                ]
                                                                                            }
                                                                                        }
                                                                                    ]}
                                                                                    rows={order.revisions}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </PaddedPaper>
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={1}>
                                                <OrderSummary access={access} callback={this.loadComponentData} order={order} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={2}>
                                                <Box m={-3}>
                                                    <Paper style={{width: '100%', background: '#F5F5F5', height: 47, borderBottom: '1px solid #ddd', borderRadius: 0}} elevation={0}>
                                                        <Box p={0.5}>
                                                            <Grid container spacing={1} justify='center' alignItems='center'>
                                                                <Grid item xs>
                                                                    <Typography variant="body2" className="ml-2">
                                                                        {`Pages: ${pdf.numPages ?? 1}`}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Grid container>
                                                                        <Grid item>
                                                                            <IconButton
                                                                                onClick={() => this.handlePdfScale(1.5)}
                                                                            >
                                                                                <FAIcon type="light" icon="search" size="small" button noMargin />
                                                                            </IconButton>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <IconButton
                                                                                onClick={() => this.handlePdfScale(false)}
                                                                                disabled={pdf.scale === "0.75"}
                                                                            >
                                                                                <FAIcon type="light" icon="search-minus" size="small" button noMargin />
                                                                            </IconButton>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <IconButton
                                                                                onClick={() => this.handlePdfScale(true)}
                                                                                disabled={pdf.scale === "2.00"}
                                                                            >
                                                                                <FAIcon type="light" icon="search-plus" size="small" button noMargin />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Paper>
                                                    <Grid container justify="center" style={{height: 'calc(100% - 47px)', background: '#777', position: 'relative', textAlign: 'center', overflowX: 'hidden', overflowY: 'auto'}}>
                                                        <Grid item>
                                                            <Box p={3}>
                                                                <Document
                                                                    file={order.signedPdf}
                                                                    onLoadSuccess={this.handlePdfLoad}
                                                                    loading={(
                                                                        <>
                                                                        </>
                                                                    )}
                                                                >
                                                                    {Array.apply(null, Array(pdf.numPages))
                                                                            .map((x, i)=>i+1)
                                                                            .map(page => (
                                                                                <Page 
                                                                                    key={`page_${page}`}
                                                                                    pageNumber={page} 
                                                                                    loading={(
                                                                                        <LoadingCircle />
                                                                                    )}
                                                                                    renderAnnotationLayer={false}
                                                                                    scale={pdf.scale}
                                                                                    style={{
                                                                                        width: '100%', 
                                                                                        position: 'absolute',
                                                                                    }}
                                                                                />
                                                                            )
                                                                    )}
                                                                </Document>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={3}>
                                                <ActivityLog activity={order.activity} fullPage />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={4}>
                                                <ViewCallLogs calls={order.call_logs} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={5}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <ViewDocuments 
                                                            access={access}
                                                            callback={this.loadComponentData}
                                                            category="Sales Orders"
                                                            documents={order.documents}
                                                            uploadFile={this.handleDeployFileUpload} 
                                                            legacyDocBox={`C:/DATA/Advance/Documents/SOPDocs/SOP${order.order_reference}`}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={6}>
                                                Invoicing
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={7}>
                                                <ViewNotes notes={order.notes} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={8}>
                                                <Paper>
                                                    <DataTable  
                                                        config={{
                                                            key: 'od_id',
                                                            alternatingRowColours: true,
                                                            isLoading: isLoading,
                                                            // responsiveImportance: true,
                                                            pagination: true,
                                                            rowsPerPage: 10,
                                                            options: {
                                                                dataRef: true,
                                                                export: {
                                                                    title: `Deliveries`,
                                                                    name: `Deliveries`,
                                                                    excel: true,
                                                                    pdf: true,
                                                                    print: true
                                                                }
                                                            }
                                                        }}
                                                        columns={[
                                                            {
                                                                heading: 'Allocated',
                                                                field: rowData => rowData.od_confirmed,
                                                                fieldFormat: 'boolean',
                                                                alignment: 'center',
                                                                sizeToContent: true,
                                                                dataRef: 'od_confirmed'
                                                            }, 
                                                            {
                                                                heading: 'Delivery',
                                                                field: rowData => (
                                                                    <Box pt={1} pb={1}>
                                                                        <Typography variant="body2" className="fw-400">
                                                                            {`Delivery ${rowData.rowNumber} of ${_.size(order.deliveries)}`}
                                                                        </Typography>
                                                                        <Typography variant="caption" className="fw-300">
                                                                            {(rowData?.purchase_order?.po_collection === 1 && (
                                                                                `Customer collection direct from ${rowData.purchase_order?.supplier?.supp_company_name}`
                                                                            )) || (
                                                                                `Fulfilled ${rowData.purchase_order ? (rowData.purchase_order?.po_ship_aa_only === 1 ? 'via Advanced Access' : 'direct') : ''} by ${rowData.purchase_order?.supplier?.supp_company_name ?? 'Advanced Access'}`
                                                                            )}
                                                                        </Typography>
                                                                    </Box>
                                                                ),
                                                                dataRef: 'rowNumber'
                                                            }, 
                                                            {
                                                                heading: 'Fulfilment Method',
                                                                field: rowData => rowData.od_type === 'PO' ? `Purchase Order (Direct)` : (rowData.od_type === "WH" && rowData.purchase_order?.po_ship_here_order === 1 ? 'Purchase Order (Ship Here)' : 'Warehouse Stock'),
                                                            },   
                                                            // {
                                                            //     heading: 'Lines',
                                                            //     field: rowData => _.size(rowData.products),
                                                            // },  
                                                            {
                                                                heading: 'Expected Despatch',
                                                                field: rowData => {
                                                                    let despatchDate = rowData.od_schedule_date;
                                                                    let isCheck = false;
                                                                    let toHere = false;
                                                                    if(rowData.pod && !rowData?.pod?.pod_delivered) {
                                                                        if(rowData.pod.pod_ship_aa_only) {
                                                                            toHere = true;
                                                                        }
                                                                        if(rowData.pod.pod_despatch_date !== '0000-00-00') {
                                                                            despatchDate = rowData.pod.pod_despatch_date;
                                                                        } else if(rowData.pod.pod_check_date !== '0000-00-00') {
                                                                            despatchDate = rowData.pod.pod_check_date;
                                                                            isCheck = true;
                                                                        } else {
                                                                            despatchDate = rowData.pod.pod_expected_despatch_date;
                                                                        }
                                                                    } else {
                                                                        if(order.order_requested_delivery_date !== '0000-00-00') {
                                                                            if(moment(despatchDate, "YYYY-MM-DD").isBefore(moment(order.order_requested_delivery_date, "YYYY-MM-DD"))) {
                                                                                despatchDate = order.order_requested_delivery_date;
                                                                            }
                                                                        }
                                                                    }
                                                                    if(rowData.despatch && (rowData.od_status === 'Despatched' || rowData.od_status === 'Invoiced')) {
                                                                        despatchDate = rowData.despatch.desp_shipping_date;
                                                                    }
                                                                    return (
                                                                        `${moment(despatchDate, "YYYY-MM-DD").format("DD/MM/YYYY")}${toHere ? ` (Ship Here)` : ``}${isCheck ? ` (Check Date)` : ``}`
                                                                    )
                                                                },
                                                            },    
                                                            {
                                                                heading: 'Net Total',
                                                                field: rowData => rowData.od_total_net,
                                                                fieldFormat: 'currency',
                                                                sizeToContent: true,
                                                                dataRef: 'od_total_net'
                                                            },    
                                                            {
                                                                heading: 'Est. Weight',
                                                                field: rowData => `${parseFloat(rowData.od_total_weight).toFixed(2)}kg`,
                                                                sizeToContent: true,
                                                                dataRef: 'od_total_weight'
                                                            },  
                                                            {
                                                                heading: 'Processed By',
                                                                field: rowData => <StaffChip staff={staff?.[rowData.od_staff_id] ?? null} />,
                                                                fieldFormat: 'staff',
                                                                dataRef: 'od_staff_id'
                                                            },   
                                                            {
                                                                heading: 'Status',
                                                                field: rowData => <><div><StatusChip status={`${rowData.od_status}${rowData.od_sys_hold === 1 || rowData.od_on_hold === 1 ? ` - On Hold` : ``}`} /></div>{!_.isEmpty(rowData?.invoice?.inv_reference) ? <Typography variant="caption">Invoice: #{rowData.invoice.inv_reference}</Typography> : ``}</>,
                                                                dataRef: 'od_status'
                                                            },    
                                                            {
                                                                actions: rowData => {
                                                                    return [
                                                                        {name: 'View Delivery', icon: 'binoculars', onClick: () => this.handleTabChange((rowData.rowIdx + 16)), doubleClick: true},
                                                                        {name: 'View Invoice', icon: 'file-pdf', disabled: rowData.od_status !== "Invoiced", color: rowData.od_status === "Invoiced" ? 'error' : undefined, onClick: () => rowData.od_legacy === 1 || rowData?.invoice?.inv_legacy === 1 ? getLegacyOrderDeliveryInvoicePDF(rowData.od_id) : getPresignedUrl(rowData?.invoice?.inv_pdf_filename)}
                                                                    ]
                                                                }
                                                            }
                                                        ]}
                                                        rows={order.deliveries}
                                                    />
                                                </Paper>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={9}>
                                                <OrderAllocation access={access} callback={this.loadComponentData} key={key} order={order} />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={10}>
                                                {!order.paid && order.order_status !== 'Cancelled' && (
                                                    <Box pb={2} pt={1} width="100%">
                                                        <Alert severity={order.outstanding < 0 ? 'success' : 'error'} variant="outlined">
                                                            <strong>Outstanding Balance {currencyFormat.format(order.outstanding)}</strong><br />
                                                            {order.outstanding < 0 ? `There is a pending refund to be processed for this sales order` : `This payment is required before the sales order can be processed`}
                                                        </Alert>
                                                    </Box>
                                                )}
                                                <SearchTransactionsTable 
                                                    orderReference={order.order_reference}
                                                    rows={order.payments} 
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={11}>
                                                <Paper>
                                                    <SearchApprovalsTable 
                                                        customer={order.customer} 
                                                        orderReference={order.order_reference} 
                                                        rows={order.approvals} 
                                                    />
                                                </Paper>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={12}>
                                                <Paper>
                                                    <DataTable  
                                                        config={{
                                                            key: 'po_id',
                                                            alternatingRowColours: true,
                                                            isLoading: isLoading,
                                                            // responsiveImportance: true,
                                                            plainPagination: true,
                                                            pagination: true,
                                                            rowsPerPage: 10,
                                                            options: {
                                                                dataRef: true,
                                                                export: {
                                                                    title: `Purchase Orders`,
                                                                    name: `Purchase-Orders`,
                                                                    excel: true,
                                                                    pdf: true,
                                                                    print: true
                                                                }
                                                            }
                                                        }}
                                                        columns={[
                                                            // {
                                                            //     heading: 'Delivery',
                                                            //     field: rowData => (
                                                            //         <Box pt={1} pb={1}>
                                                            //             <Typography variant="body2" className="fw-400">
                                                            //                 {`Delivery ${rowData.od_idx} of ${_.size(order.deliveries)}`}
                                                            //             </Typography>
                                                            //             <Typography variant="caption">
                                                            //                 {`Fulfilled by ${rowData.purchase_order?.supplier?.supp_company_name ?? 'Advanced Access'}`}
                                                            //             </Typography>
                                                            //         </Box>
                                                            //     ),
                                                            //     dataRef: 'rowNumber',
                                                            // }, 
                                                            {
                                                                heading: 'Purchase Order',
                                                                field: rowData => rowData.po_reference,
                                                            },  
                                                            {
                                                                heading: 'Supplier',
                                                                field: rowData => rowData?.supplier?.supp_company_name,
                                                            },  
                                                            {
                                                                heading: 'Net Total',
                                                                field: rowData => rowData?.po_total_net,
                                                                fieldFormat: 'currency'
                                                            },  
                                                            {
                                                                heading: 'Confirmed',
                                                                field: rowData => rowData.po_confirmation_date ?? '-',
                                                                fieldFormat: 'date',
                                                            },   
                                                            {
                                                                heading: 'Despatched',
                                                                field: rowData => rowData.po_despatched_date ?? '-',
                                                                fieldFormat: 'date',
                                                            },   
                                                            {
                                                                heading: 'Direct Collection',
                                                                field: rowData => rowData?.po_collection,
                                                                fieldFormat: 'boolean',
                                                                alignment: 'center',
                                                            }, 
                                                            {
                                                                heading: 'Direct Despatch',
                                                                field: rowData => !rowData?.po_ship_aa_only,
                                                                fieldFormat: 'boolean',
                                                                alignment: 'center',
                                                            },    
                                                            {
                                                                heading: 'Ship Here',
                                                                field: rowData => rowData?.po_ship_aa_only,
                                                                fieldFormat: 'boolean',
                                                                alignment: 'center',
                                                            },     
                                                            {
                                                                heading: 'Status',
                                                                field: rowData => <StatusChip status={rowData.po_status} />,
                                                            },      
                                                            {
                                                                actions: rowData => {
                                                                    return [
                                                                        {name: 'View', icon: 'binoculars', onClick: () => this.props.history.push(`/purchase-orders/${rowData?.po_id}`), doubleClick: true},
                                                                        {name: 'Download', icon: 'file-pdf', color: 'error',  onClick: () => rowData?.po_legacy === 1 ? getLegacyPurchaseOrderPDF(rowData?.po_id ?? 0) : getPresignedUrl(rowData?.po_pdf_filename ?? 0)}
                                                                    ]
                                                                }
                                                            }
                                                        ]}
                                                        rows={_.uniqBy(_.concat(order.purchase_orders, _.map(_.filter(order.deliveries, el => el.purchase_order), _el => _el.purchase_order)), 'po_reference')}
                                                    />
                                                </Paper>
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={13}>
                                                <EmailLog
                                                    key={key}
                                                    filter="order"
                                                    filterId={order.order_id}
                                                    staff={this.props.staff}
                                                    deployConfirmation={deployConfirmation}
                                                    deploySnackBar={deploySnackBar}
                                                    deployDialog={deployDialog}
                                                    closeDialog={closeDialog}
                                                    noSearch
                                                    // sendEmail={this.handleDeploySendEmail}
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={14}>
                                                <ViewActions
                                                    key={key}
                                                    filter="order"
                                                    filterId={order.order_id}
                                                    staff={staff}
                                                    deployDialog={deployDialog}
                                                    closeDialog={closeDialog}
                                                    callback={this.loadComponentData}
                                                    noPagination
                                                />
                                            </TabPanel>
                                            <TabPanel value={currentTab} index={15}>
                                                <Grid container spacing={3}> 
                                                    <Grid item xs={12}>
                                                        <Paper>
                                                            <Box p={3}>
                                                                <Grid container spacing={1} alignItems='center'>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="h6">
                                                                            Card Orders
                                                                        </Typography>  
                                                                        <Box pb={1}>
                                                                            <Grid container alignItems='center'>
                                                                                <Grid item>
                                                                                    <FAIcon icon="exclamation-circle" className="textWarning mr-1" size={15} />
                                                                                </Grid>
                                                                                <Grid item xs>
                                                                                    <Typography variant="caption">
                                                                                        This order is subject to the following card orders
                                                                                        {_.some(order.emails, el => el.oe_type === "Authorisation") && (
                                                                                            `, authorisation letters previously sent : ${momentFormatDateTime(_.find(_.orderBy(order.emails, 'oe_id', 'desc'), _el => _el.oe_type === "Authorisation")?.oe_created_datetime ?? null)}`
                                                                                        )}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Box>
                                                                    </Grid>
                                                                    {_.map(order.products, (op, idx) => {
                                                                        if(op.card_order) {
                                                                            const   rowData     =   op,
                                                                                    notes       =   _.size(rowData?.notes ?? rowData.productData?.notes ?? rowData?.productData?.notes ?? rowData?.product?.notes ?? []),
                                                                                    status      =   rowData.status ?? rowData.productData?.status ?? rowData.productData?.product_status ?? rowData.product?.product_status ?? 'Unknown',
                                                                                    replacement =   (rowData.productRep > 0 || rowData.productData?.productRep > 0 || rowData.productData?.product_rep_product_id > 0 || rowData.product?.product_rep_product_id > 0),
                                                                                    inStock     =   rowData.instock ?? rowData.productData?.instock ?? rowData.productData?.stock_count ?? rowData.product?.stock_count ?? 0,
                                                                                    preOrdered  =   rowData.preOrdered ?? rowData.productData?.preOrdered ?? rowData.productData?.stock_on_order_allocated_count ?? rowData.product?.stock_on_order_allocated_count ?? 0,
                                                                                    available   =   rowData.available ?? rowData.productData?.available ?? rowData.productData?.stock_available_count ?? rowData.product?.stock_available_count ?? 0,
                                                                                    allocated   =   rowData.allocated ?? rowData.productData?.allocated ?? rowData.productData?.stock_allocated_count ?? rowData.product?.stock_allocated_count ?? 0,
                                                                                    onOrder     =   rowData.onOrder ?? rowData.productData?.onOrder ?? rowData.productData?.stock_on_order_count ?? rowData.product?.stock_on_order_count ?? 0,
                                                                                    chkPr       =   (rowData.chkPr || rowData.productData?.chkPr || rowData.productData?.product_check_price === 1 || rowData.product?.product_check_price === 1),
                                                                                    stck        =   (rowData.stkIt || rowData.productData?.stkIt || rowData.productData?.product_stock_item === 1 || rowData.product?.product_stock_item === 1),
                                                                                    packSize    =   rowData.packSize || rowData.productData?.packSize || rowData.productData?.product_pack_size || rowData.product?.product_pack_size,
                                                                                    quantity    =   rowData.quantity,
                                                                                    cardOrder   =   rowData.productData?.cDb === 1 || rowData.cardDb === 1 || rowData.productData?.cardDb === 1;

                                                                            return (
                                                                                <Grid item xs={12} key={idx}>
                                                                                    <Box className="content-light-grey" p={3} mb={2}>
                                                                                        <Box pb={3}>
                                                                                            <Grid container spacing={1} justify="space-between" alignItems='center'>
                                                                                                <Grid item>
                                                                                                    <Typography variant="h6">
                                                                                                        Card Order - CARD00{op?.card_order?.co_ref}
                                                                                                    </Typography>
                                                                                                </Grid>
                                                                                                <Grid item>
                                                                                                    <Grid container alignItems='center'>
                                                                                                        <Grid item xs align='right'>
                                                                                                            <Typography variant="caption" className="fw-400 pr-1">
                                                                                                                Order Created By 
                                                                                                            </Typography>
                                                                                                        </Grid>
                                                                                                        <Grid item>
                                                                                                            <StaffChip staff={staff?.[rowData?.card_order?.co_created_by_staff_id] ?? null} />
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Box>
                                                                                        <Box className="content-light-white" style={{borderBottom: 0}}>
                                                                                            {(op?.card_order?.co_status_id === 7 && (
                                                                                                <Alert severity="error">
                                                                                                    This card order has been cancelled
                                                                                                </Alert>
                                                                                            )) || (
                                                                                                <Alert severity={op?.card_order?.co_status_id === 1 ? 'warning' : 'success'}>
                                                                                                    {op?.card_order?.co_status_id === 1 ? 'This card order has not yet been authorised by the customer' : 'This card order has been authorised by the customer'}
                                                                                                </Alert>
                                                                                            )}
                                                                                        </Box>
                                                                                        <Box className="content-light-white" p={3}>
                                                                                            <Table>
                                                                                                <TableBody>
                                                                                                    <TableRow className='pl-1'>
                                                                                                        <TableCell className={classes.td} style={{width: '65%'}}>
                                                                                                            <Grid container spacing={1} alignItems='center' style={{marginTop: -12}}>
                                                                                                                <Grid item className='pr-2'>
                                                                                                                    <Avatar src={rowData.image ? `${CLOUDFRONT_URL}${rowData.image}` : logo} style={{overflow:'visible', width: 75, height: 75}} />
                                                                                                                </Grid>
                                                                                                                <Grid item xs>
                                                                                                                    <Grid container alignItems="flex-end" className="pt-1 pb-1">
                                                                                                                        <Grid item>
                                                                                                                            <Typography variant="body2" className="fw-400">
                                                                                                                                {rowData.productCode}
                                                                                                                            </Typography>
                                                                                                                        </Grid>
                                                                                                                        <Grid item xs={12}>
                                                                                                                            <Typography variant="caption" component="div">
                                                                                                                                {rowData.productName}
                                                                                                                            </Typography>
                                                                                                                        </Grid>
                                                                                                                        {(rowData?.efs === 'Invoiced' || rowData?.efs === 'Despatched') && (
                                                                                                                            <Grid item xs={12}>
                                                                                                                                <Typography variant="caption" component="div">
                                                                                                                                    {`${inStock} In Stock | ${allocated} Allocated | ${available} Available | ${onOrder} On Order | ${preOrdered} Pre-ordered`}
                                                                                                                                </Typography>
                                                                                                                            </Grid>
                                                                                                                        )}
                                                                                                                        <Grid item>
                                                                                                                            {((rowData?.efs === 'Invoiced' || rowData?.efs === 'Despatched') && (
                                                                                                                                <InsightChip 
                                                                                                                                    color='#ef3340'
                                                                                                                                    icon='exclamation-triangle'
                                                                                                                                    label={`${rowData.efs} - This line cannot be amended or removed`}
                                                                                                                                    marginRight={8}
                                                                                                                                    paddingLeft={8}
                                                                                                                                    paddingRight={4}
                                                                                                                                />
                                                                                                                            )) || (
                                                                                                                                <Box pt={1}>
                                                                                                                                    <ProductStockChip
                                                                                                                                            qty={quantity} 
                                                                                                                                            rep={replacement}
                                                                                                                                            status={status}
                                                                                                                                            stocked={stck}
                                                                                                                                            stockAvailable={available}
                                                                                                                                            stockOnOrderAvailable={(onOrder - preOrdered)}
                                                                                                                                        />
                                                                                                                                </Box>
                                                                                                                            )}
                                                                                                                        </Grid>
                                                                                                                        {cardOrder && (
                                                                                                                            <Grid item>
                                                                                                                                <Box pt={1}>
                                                                                                                                    <InsightChip 
                                                                                                                                        background="#ef3340"
                                                                                                                                        color="#fff"
                                                                                                                                        marginRight={8}
                                                                                                                                        paddingLeft={8}
                                                                                                                                        icon="exclamation-triangle"
                                                                                                                                        label={'Card Order Authorisation'}
                                                                                                                                        size={11}
                                                                                                                                    />
                                                                                                                                </Box>
                                                                                                                            </Grid>
                                                                                                                        )}
                                                                                                                        {chkPr && (
                                                                                                                            <Grid item>
                                                                                                                                <Box pt={1}>
                                                                                                                                    <InsightChip 
                                                                                                                                        background="#FF5722"
                                                                                                                                        color="#fff"
                                                                                                                                        marginRight={8}
                                                                                                                                        paddingLeft={8}
                                                                                                                                        icon="exclamation-triangle"
                                                                                                                                        label='Check Price and Availability'
                                                                                                                                    />
                                                                                                                                </Box>
                                                                                                                            </Grid>
                                                                                                                        )}
                                                                                                                        {notes > 0 && (
                                                                                                                            <Grid item>
                                                                                                                                <Box pt={1}>
                                                                                                                                    <InsightChip 
                                                                                                                                        background="#4CAF50"
                                                                                                                                        color="#fff"
                                                                                                                                        marginRight={8}
                                                                                                                                        paddingLeft={8}
                                                                                                                                        icon="comment-alt-lines"
                                                                                                                                        label={notes}
                                                                                                                                        onClick={() => this.handleDeployNotes(rowData.productCode, rowData.notes ?? rowData.productData?.notes ?? [])}
                                                                                                                                        size={11}
                                                                                                                                        tooltip='View Notes'
                                                                                                                                    />
                                                                                                                                </Box>
                                                                                                                            </Grid>
                                                                                                                        )}
                                                                                                                    </Grid>
                                                                                                                </Grid>
                                                                                                            </Grid>
                                                                                                        </TableCell>
                                                                                                        <TableCell className={classes.td} style={{width: '35%'}}>
                                                                                                        </TableCell>
                                                                                                    </TableRow>  
                                                                                                </TableBody>
                                                                                            </Table>
                                                                                            <Box pt={3} pb={2}>
                                                                                                <Divider />
                                                                                            </Box>
                                                                                            <Grid container spacing={3}>
                                                                                                <Grid item xs={5}>
                                                                                                    <StackTable
                                                                                                        elevation={0}
                                                                                                        padding={0}
                                                                                                        heading="Card Order Details"
                                                                                                        rows={[ 
                                                                                                            {
                                                                                                                heading: 'Status',
                                                                                                                value: <StatusChip status={cardOrderStatus(op?.card_order?.co_status_id) ?? "Unknown"} />
                                                                                                            },
                                                                                                            // {
                                                                                                            //     heading: "Customer Order No",
                                                                                                            //     value: op?.card_order?.co_cust_ref ?? false
                                                                                                            // },
                                                                                                            {
                                                                                                                heading: "Customer Site",
                                                                                                                value: `${op.card_order?.site?.site_code ?? ""} - ${op.card_order?.site?.site_description ?? ""}`
                                                                                                            },
                                                                                                            {
                                                                                                                heading: "Card Format",
                                                                                                                value: op?.card_order?.format?.format_name ?? false
                                                                                                            },
                                                                                                            {
                                                                                                                heading: "Card Type",
                                                                                                                value: op?.card_order?.type?.type_name ?? false
                                                                                                            },
                                                                                                            {
                                                                                                                heading: "Programmer Type",
                                                                                                                value: op?.card_order?.programmer?.programmer_name ?? false
                                                                                                            },
                                                                                                            {
                                                                                                                heading: "Order Quantity",
                                                                                                                value: rowData.quantity
                                                                                                            },
                                                                                                            {
                                                                                                                heading: "Pack Size",
                                                                                                                value: packSize
                                                                                                            },
                                                                                                            {
                                                                                                                heading: "Total Quantity",
                                                                                                                value: parseInt(rowData.quantity) * parseInt(packSize)
                                                                                                            },
                                                                                                            {
                                                                                                                heading: "Internal Range Start",
                                                                                                                value: op?.card_order?.co_internal_range_start ?? false
                                                                                                            },
                                                                                                            {
                                                                                                                heading: "Internal Range End",
                                                                                                                value: op?.card_order?.co_internal_range_end ?? false
                                                                                                            },
                                                                                                            {
                                                                                                                heading: "External Range Start",
                                                                                                                value: op?.card_order?.co_external_range_start ?? false
                                                                                                            },
                                                                                                            {
                                                                                                                heading: "External Range End",
                                                                                                                value: op?.card_order?.co_external_range_end ?? false
                                                                                                            },
                                                                                                            {
                                                                                                                heading: "Card Order Notes",
                                                                                                                value: op?.card_order?.co_notes ?? false
                                                                                                            }
                                                                                                        ]}
                                                                                                    /> 
                                                                                                </Grid>
                                                                                                <Grid item xs={7}>
                                                                                                    <Grid container spacing={3}>
                                                                                                        <Grid item xs={12}>
                                                                                                            <StackTable
                                                                                                                elevation={0}
                                                                                                                padding={0}
                                                                                                                heading="Card Order Files"
                                                                                                                rows={_.map(op?.card_order?.files, f => {
                                                                                                                    const Element = _.isEmpty(f.file_key) ? Typography : Link;
                                                                                                                    return (
                                                                                                                        _.assign({
                                                                                                                            heading: `${f.file_type === 'ORDER_AUTH' ? 'Authorisation Letter' : 'Customer Response'}`,
                                                                                                                            value: (
                                                                                                                                <Grid container spacing={1} alignItems='center'>
                                                                                                                                    <Grid item>
                                                                                                                                        <FAIcon icon="file-pdf" className="textError" size={15} noMargin />
                                                                                                                                    </Grid>
                                                                                                                                    <Grid item xs>
                                                                                                                                        <Typography variant="body2">
                                                                                                                                            <Element className="textDefault" href={`${CLOUDFRONT_URL}${f.file_key}`} target='_blank' variant={_.isEmpty(f.file_key) ? 'body2' : undefined}>{f.file_name}</Element>
                                                                                                                                        </Typography>
                                                                                                                                    </Grid>
                                                                                                                                </Grid>
                                                                                                                            )
                                                                                                                        })
                                                                                                                    )
                                                                                                                })}
                                                                                                            />
                                                                                                        </Grid>
                                                                                                        <Grid item xs={12}>
                                                                                                            <StackTable
                                                                                                                elevation={0}
                                                                                                                padding={0}
                                                                                                                heading="Card Order Audit"
                                                                                                                rows={_.map(op?.card_order?.audits, a => (
                                                                                                                    _.assign({
                                                                                                                        heading: `${momentFormatDateTime(a?.audit_created_datetime)}`,
                                                                                                                        value: (<Grid container alignItems='center'><Grid item><Typography variant="caption" className="fw-400 mr-1">{cardOrderStatus(a?.audit_status_id)}</Typography></Grid><Grid item xs><StaffChip staff={staff[a?.audit_created_by_staff_id ?? 0]} /></Grid></Grid>),
                                                                                                                    })
                                                                                                                ))}
                                                                                                            /> 
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Grid>
                                                                            )
                                                                        }
                                                                    })}
                                                                </Grid>
                                                            </Box>
                                                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </TabPanel>
                                            {_.map(order.deliveries, (od, idx) => (
                                                <TabPanel value={currentTab} index={(idx + 16)}>
                                                    <DeliverySummary access={access} callback={this.loadComponentData} od={od} order={order} idx={(idx + 1)} />
                                                </TabPanel>
                                            ))}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.side} style={{height: (contentHeight - VIEW_PAGE_APP_BAR_HEIGHT)}}>
                                    <Tabs
                                        color="primary"
                                        onChange={this.handleTabChange}
                                        orientation="vertical"
                                        TabIndicatorProps={{
                                            style: {
                                                display: 'none'
                                            }
                                        }}
                                        value={currentTab}
                                        variant="scrollable"
                                    >
                                        {_.map(tabs, (tab, idx) => (
                                            (tab.divider && (
                                                <Divider key={idx} />
                                            )) || (
                                                <Tab 
                                                    classes={{textColorInherit: classes.tab, selected: classes.tabSelected}} 
                                                    disabled={tab.disabled}
                                                    index={tab.index}
                                                    value={tab.index}
                                                    key={idx} 
                                                    label={tab.label} 
                                                    onClick={() => this.handleTabChange(tab.index, tab.delivery ?? false)} 
                                                />
                                            )
                                        ))}
                                    </Tabs>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </>
            )
        }
    }

const mapStateToProps = state => (
    {
        staff: state.notifications.status,
        loggedInStaff: state.staffAuth.staff,
        ui: state.ui
    }
)

const mapDispatchToProps = dispatch => (
    {
        deployConfirmation: (message, success, cancel = false, successText = null, cancelText = null, successOnly = false) => dispatch(deployConfirmation(message, success, cancel, successText, cancelText, successOnly)),
        deploySnackBar: (variant, message) => dispatch(deploySnackBar(variant, message)),
        closeDialog: () => dispatch(closeDialog()),
        deployDialog: (content, disableDialogContent = false, title = '', variant = 'standard', size = 'md', fullscreen = false, disableExit = false) => dispatch(deployDialog(content, disableDialogContent, title, variant, size, fullscreen, disableExit)),
    }
)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ViewPage)(withRouter(ViewOrder)));