import React from 'react';
import _ from 'lodash';

import { Editor } from 'react-draft-wysiwyg';
import { getDefaultKeyBinding, RichUtils } from 'draft-js';
import { FormControl, InputLabel } from '@material-ui/core';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const WYSIWYG = ({onChange, editorState, height, label, margin}) => {
    
    const handleKeyCommand = (command) => {

        if(command === 'enter_command') {
            return 'handled';
        }

        return 'not-handled';

    }

    const keyBindingFn = e => {

        if (e.keyCode === 13) {
            const newState = RichUtils.insertSoftNewline(editorState);
            onChange(newState);
            return;
        }

        return getDefaultKeyBinding(e);

    }

    return (
        <FormControl margin={margin ?? 'normal'} fullWidth>
            {!_.isEmpty(label) && (
                <InputLabel shrink={true} style={{display: 'block'}}>{label}</InputLabel>
            )}
            <FormControl margin="normal" fullWidth style={{marginTop: 24}}>
                <Editor
                    spellCheck
                    editorState={editorState}
                    editorStyle={{border: '1px solid #ddd', fontFamily: 'Arial, sans-serif', paddingLeft: 10, paddingRight: 10, height: height ? height : undefined, maxHeight: 325, fontSize: 16}}
                    onEditorStateChange={onChange} 
                    keyBindingFn={keyBindingFn}
                    handleKeyCommand={handleKeyCommand}
                    toolbar={{
                        options: [
                            'inline',
                            'fontSize', 
                            'list',
                            'colorPicker',
                            'link', 
                            'emoji', 
                        ],
                        fontSize: {
                            options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 36, 48, 60, 72, 96],
                        },
                        colorPicker: {
                            colors: ['#ef3340', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                            'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                            'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                            'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                            'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                            'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                        },
                        inline: {
                            options: [
                                'bold', 
                                'italic', 
                                'underline'
                            ]
                        },
                    }}
                    toolbarStyle={{
                        padding: 0,
                        margin: 0,
                        border: 'none',
                        marginLeft: -4
                    }}
                />
            </FormControl>
        </FormControl>
    )
}

export default WYSIWYG;