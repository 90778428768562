import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";

import StaffChip from 'Components/Common/Chips/StaffChip';
import Search from 'Components/Common/Search/Search';

const SaleAccTurnover = () => {
    const staff = useSelector(state => state.notifications.status);

    const [incMargins, setIncMargins] = useState(false);

    const cols = [
        {
            heading: 'Acc Name',
            field: rowData => rowData.cn,
            dataRef: 'cn',
            important: true,
            sizeToContent: true
        },
        {
            heading: 'Acc Number',
            field: rowData => rowData.sgr,
            dataRef: 'sgr',
            important: true,
            sizeToContent: true
        },
        {
            heading: 'Acc Code',
            field: rowData => rowData.cac,
            dataRef: 'cac',
            important: true,
            sizeToContent: true
        },
        {
            heading: 'YE 2021',
            field: rowData => rowData.yre?.['2020'],
            fieldFormat: 'currency',
            dataRef: 'yre.2020',
            important: true,
            sizeToContent: true
        },
        {
            heading: 'YE 2022',
            field: rowData => rowData.yre?.['2021'],
            fieldFormat: 'currency',
            dataRef: 'yre.2021',
            important: true,
            sizeToContent: true
        },
        {
            heading: 'YE 2023',
            field: rowData => rowData.yre?.['2022'],
            fieldFormat: 'currency',
            dataRef: 'yre.2022',
            important: true,
            sizeToContent: true
        },
        {
            heading: 'YE 2024',
            field: rowData => rowData.yre?.['2023'],
            fieldFormat: 'currency',
            dataRef: 'yre.2023',
            important: true,
            sizeToContent: true
        },
        {
            heading: 'YE 2025',
            field: rowData => rowData.ytd,
            fieldFormat: 'currency',
            dataRef: 'ytd',
            important: true,
            sizeToContent: true
        }
    ];

    if(incMargins) {
        cols.push(
            {
                heading: 'YE Margin 2021',
                field: rowData => rowData.mre?.['2020'],
                fieldFormat: 'percentage:2',
                dataRef: 'mre.2020',
                important: true,
                sizeToContent: true
            },
            {
                heading: 'YE Margin 2022',
                field: rowData => rowData.mre?.['2021'],
                fieldFormat: 'percentage:2',
                dataRef: 'mre.2021',
                important: true,
                sizeToContent: true
            },
            {
                heading: 'YE Margin 2023',
                field: rowData => rowData.mre?.['2022'],
                fieldFormat: 'percentage:2',
                dataRef: 'mre.2022',
                important: true,
                sizeToContent: true
            },
            {
                heading: 'YE Margin 2024',
                field: rowData => rowData.mre?.['2023'],
                fieldFormat: 'percentage:2',
                dataRef: 'mre.2023',
                important: true,
                sizeToContent: true
            },
            {
                heading: 'YE Margin 2025',
                field: rowData => rowData.mtd,
                fieldFormat: 'percentage:2',
                dataRef: 'mtd',
                important: true,
                sizeToContent: true
            }
        )
    }

    cols.push(
        {
            heading: 'Free Delivery Net Spend',
            field: rowData => rowData.fd,
            fieldFormat: 'currency',
            dataRef: 'fd',
            important: true,
            sizeToContent: true
        },
        {
            heading: 'Proactive Responsibility',
            field: rowData => <StaffChip staff={staff?.[rowData?.sm]} />,
            fieldFormat: 'staff',
            dataRef: 'sm',
            important: true,
            sizeToContent: true,
        },
        // {
        //     heading: 'Follow Up',
        //     field: rowData => rowData.fu,
        //     dataRef: 'fu',
        //     important: true,
        //     sizeToContent: true,
        //     alignment: 'center'
        // },
        {
            heading: 'Primary Contact',
            field: rowData => rowData.pc,
            dataRef: 'pc',
            important: true,
            sizeToContent: true
        },
        {
            heading: 'Address 1',
            field: rowData => rowData.addr?.l1,
            dataRef: 'addr.l1',
            important: true,
            sizeToContent: true
        },
        {
            heading: 'Address 2',
            field: rowData => rowData.addr?.l2,
            dataRef: 'addr.l2',
            important: true,
            sizeToContent: true
        },
        {
            heading: 'Town',
            field: rowData => rowData.addr?.t,
            dataRef: 'addr.t',
            important: true,
            sizeToContent: true
        },
        {
            heading: 'County',
            field: rowData => rowData.addr?.c,
            dataRef: 'addr.c',
            important: true,
            sizeToContent: true
        },
        {
            heading: 'Postcode',
            field: rowData => rowData.addr?.pc,
            dataRef: 'addr.pc',
            important: true,
            sizeToContent: true
        },
        // {
        //     heading: 'Area',
        //     field: rowData => rowData.addr?.area,
        //     dataRef: 'addr.area',
        //     important: true,
        //     sizeToContent: true
        // },
        {
            heading: 'Type',
            field: rowData => rowData.ty,
            dataRef: 'ty',
            important: true,
            sizeToContent: true
        },
        {
            heading: 'Source',
            field: rowData => rowData.src,
            dataRef: 'src',
            important: true,
            sizeToContent: true
        },
        {
            heading: 'Region',
            field: rowData => rowData.cr,
            dataRef: 'cr',
            important: true,
            sizeToContent: true
        },
        {
            heading: 'Legal Status',
            field: rowData => rowData.ls || '-',
            dataRef: 'ls',
            important: true,
            sizeToContent: true
        },
        {
            heading: 'Company Number',
            field: rowData => rowData.rn || '-',
            dataRef: 'rn',
            important: true,
            sizeToContent: true
        },
        {
            heading: 'VAT Number',
            field: rowData => rowData.vn || '-',
            dataRef: 'vn',
            important: true,
            sizeToContent: true
        },
        {
            heading: 'Last Visit',
            field: rowData => rowData.lv,
            fieldFormat: 'date',
            dataRef: 'lv',
            important: true,
            sizeToContent: true
        }
    );

    return (
        <Grid container spacing={1} style={{position: 'relative'}}>
            <Grid item xs={12} style={{position: 'absolute', right: 0, top: 5}}>
                <FormControlLabel
                    control={
                        <Switch color="primary" checked={incMargins} onChange={() => setIncMargins(!incMargins)} value="true" />
                    }
                    label={<span className="fw-400">Show Margins</span>}
                />
            </Grid>
            <Grid item xs={12}>
                <Search
                    configuration={{
                        alternatingRowColours: true,
                        apiRoute: '/reporting/salesAccountTurnover',
                        noAutoLoad: true,
                        columns: cols,
                        dataRef: true,
                        excel: true,
                        key: 'i',
                        name: 'sales-account-turnover',
                        noResultsText: 'Sorry, no sales account turnover analysis is available to display',
                        pagination: true,
                        pageTitleData: [0, 'Reporting', 'Sales'],
                        pdf: true,
                        persistenceId: 'saleAccTurnover',
                        placeholderData: false,
                        print: true,
                        pulseResults: 0,
                        responsiveImportance: true,
                        rowsPerPage: 50,
                        showCustomer: false,
                        showDate: false,
                        showDateRange: false,
                        showFullTotals: false,
                        showSearch: true,
                        showRegion: true,
                        showSupplier: true,
                        showTotals: false,
                        title: 'Sales Account Turnover Analysis Report'
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default SaleAccTurnover;