import React from 'react';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import FAIcon from 'Components/Common/Icons/FontAwesome/FAIcon';

const InsightChip = ({border = 0, background, className, color, disabled, icon, label, large, marginLeft, marginRight, paddingLeft, onClick, onDelete, textDefault, size = 13, chipSize = 'small', tooltip = null, type = 'solid'}) => {
    switch(tooltip) {
        case null:
        case undefined:
            return (
                <Chip
                    avatar={icon ? <FAIcon type={type} className={className} size={size} icon={icon} style={{color: icon === `file-pdf` ? `#d32f2f` : color}} noMargin /> : undefined}
                    className={className}
                    label={label}
                    size={chipSize ?? 'small'}
                    variant="outlined"
                    style={{border: onClick ? '1px solid rgba(0,0,0,0.1)' : border, background: onClick && !background ? 'rgba(0,0,0,0.035)' : background, fontWeight: 400, fontSize: large ? '.9rem' : undefined, paddingLeft: paddingLeft, marginLeft: marginLeft, marginRight: marginRight, color: !textDefault ? color : undefined, cursor: onClick ? 'pointer' : undefined}}
                    onClick={onClick}
                    onDelete={onDelete}
                    disabled={disabled}
                />
            )
        default:
            return (
                <Tooltip arrow enterDelay={0} enterTouchDelay={0} placement="right" title={tooltip}>
                    <Chip
                        avatar={icon ? <FAIcon type={type} className={className} size={size} icon={icon} style={{color: icon === `file-pdf` ? `#d32f2f` : color}} noMargin /> : undefined}
                        className={className}
                        label={label}
                        size={chipSize ?? 'small'}
                        variant="outlined"
                        style={{border: onClick ? '1px solid rgba(0,0,0,0.1)' : border, background: onClick && !background ? 'rgba(0,0,0,0.035)' : background, fontWeight: 400, fontSize: large ? '.9rem' : undefined, paddingLeft: paddingLeft, marginLeft: marginLeft, marginRight: marginRight, color: !textDefault ? color : undefined, cursor: onClick ? 'pointer' : undefined}}
                        onClick={onClick}
                        onDelete={onDelete}
                        disabled={disabled}
                    />
                </Tooltip>
            )
    }
}

export default InsightChip;